@charset "UTF-8";
/*!

Copyright 2015-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

body {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400;
  color: #182026;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif; }

p {
  margin-top: 0;
  margin-bottom: 10px; }

small {
  font-size: 12px; }

strong {
  font-weight: 600; }

::-moz-selection {
  background: rgba(125, 188, 255, 0.6); }

::selection {
  background: rgba(125, 188, 255, 0.6); }

.bp3-heading {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0; }

.bp3-dark .bp3-heading {
  color: #f5f8fa; }

h1.bp3-heading, .bp3-running-text h1 {
  line-height: 40px;
  font-size: 36px; }

h2.bp3-heading, .bp3-running-text h2 {
  line-height: 32px;
  font-size: 28px; }

h3.bp3-heading, .bp3-running-text h3 {
  line-height: 25px;
  font-size: 22px; }

h4.bp3-heading, .bp3-running-text h4 {
  line-height: 21px;
  font-size: 18px; }

h5.bp3-heading, .bp3-running-text h5 {
  line-height: 19px;
  font-size: 16px; }

h6.bp3-heading, .bp3-running-text h6 {
  line-height: 16px;
  font-size: 14px; }

.bp3-ui-text {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400; }

.bp3-monospace-text {
  text-transform: none;
  font-family: monospace; }

.bp3-text-muted {
  color: #5c7080; }

.bp3-dark .bp3-text-muted {
  color: #a7b6c2; }

.bp3-text-disabled {
  color: rgba(92, 112, 128, 0.6); }

.bp3-dark .bp3-text-disabled {
  color: rgba(167, 182, 194, 0.6); }

.bp3-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

.bp3-running-text {
  line-height: 1.5;
  font-size: 14px; }

.bp3-running-text h1 {
  color: #182026;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px; }

.bp3-dark .bp3-running-text h1 {
  color: #f5f8fa; }

.bp3-running-text h2 {
  color: #182026;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px; }

.bp3-dark .bp3-running-text h2 {
  color: #f5f8fa; }

.bp3-running-text h3 {
  color: #182026;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px; }

.bp3-dark .bp3-running-text h3 {
  color: #f5f8fa; }

.bp3-running-text h4 {
  color: #182026;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px; }

.bp3-dark .bp3-running-text h4 {
  color: #f5f8fa; }

.bp3-running-text h5 {
  color: #182026;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px; }

.bp3-dark .bp3-running-text h5 {
  color: #f5f8fa; }

.bp3-running-text h6 {
  color: #182026;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px; }

.bp3-dark .bp3-running-text h6 {
  color: #f5f8fa; }

.bp3-running-text hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid rgba(16, 22, 26, 0.15); }

.bp3-dark .bp3-running-text hr {
  border-color: rgba(255, 255, 255, 0.15); }

.bp3-running-text p {
  margin: 0 0 10px;
  padding: 0; }

.bp3-text-large {
  font-size: 16px; }

.bp3-text-small {
  font-size: 12px; }

a {
  text-decoration: none;
  color: #106ba3; }

a:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #106ba3; }

a .bp3-icon, a .bp3-icon-standard, a .bp3-icon-large {
  color: inherit; }

a code,
.bp3-dark a code {
  color: inherit; }

.bp3-dark a,
.bp3-dark a:hover {
  color: #48aff0; }

.bp3-dark a .bp3-icon, .bp3-dark a .bp3-icon-standard, .bp3-dark a .bp3-icon-large,
.bp3-dark a:hover .bp3-icon,
.bp3-dark a:hover .bp3-icon-standard,
.bp3-dark a:hover .bp3-icon-large {
  color: inherit; }

.bp3-running-text code, .bp3-code {
  text-transform: none;
  font-family: monospace;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  color: #5c7080;
  font-size: smaller; }

.bp3-dark .bp3-running-text code, .bp3-running-text .bp3-dark code, .bp3-dark .bp3-code {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #a7b6c2; }

.bp3-running-text a > code, a > .bp3-code {
  color: #137cbd; }

.bp3-dark .bp3-running-text a > code, .bp3-running-text .bp3-dark a > code, .bp3-dark a > .bp3-code {
  color: inherit; }

.bp3-running-text pre, .bp3-code-block {
  text-transform: none;
  font-family: monospace;
  display: block;
  margin: 10px 0;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  background: rgba(255, 255, 255, 0.7);
  padding: 13px 15px 12px;
  line-height: 1.4;
  color: #182026;
  font-size: 13px;
  word-break: break-all;
  word-wrap: break-word; }

.bp3-dark .bp3-running-text pre, .bp3-running-text .bp3-dark pre, .bp3-dark .bp3-code-block {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #f5f8fa; }

.bp3-running-text pre > code, .bp3-code-block > code {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  padding: 0;
  color: inherit;
  font-size: inherit; }

.bp3-running-text kbd, .bp3-key {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  min-width: 24px;
  height: 24px;
  padding: 3px 6px;
  vertical-align: middle;
  line-height: 24px;
  color: #5c7080;
  font-family: inherit;
  font-size: 12px; }

.bp3-running-text kbd .bp3-icon, .bp3-key .bp3-icon, .bp3-running-text kbd .bp3-icon-standard, .bp3-key .bp3-icon-standard, .bp3-running-text kbd .bp3-icon-large, .bp3-key .bp3-icon-large {
  margin-right: 5px; }

.bp3-dark .bp3-running-text kbd, .bp3-running-text .bp3-dark kbd, .bp3-dark .bp3-key {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  background: #394b59;
  color: #a7b6c2; }

.bp3-running-text blockquote, .bp3-blockquote {
  margin: 0 0 10px;
  border-left: solid 4px rgba(167, 182, 194, 0.5);
  padding: 0 20px; }

.bp3-dark .bp3-running-text blockquote, .bp3-running-text .bp3-dark blockquote, .bp3-dark .bp3-blockquote {
  border-color: rgba(115, 134, 148, 0.5); }

.bp3-running-text ul,
.bp3-running-text ol, .bp3-list {
  margin: 10px 0;
  padding-left: 30px; }

.bp3-running-text ul li:not(:last-child), .bp3-running-text ol li:not(:last-child), .bp3-list li:not(:last-child) {
  margin-bottom: 5px; }

.bp3-running-text ul ol, .bp3-running-text ol ol, .bp3-list ol,
.bp3-running-text ul ul,
.bp3-running-text ol ul,
.bp3-list ul {
  margin-top: 5px; }

.bp3-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none; }

.bp3-list-unstyled li {
  padding: 0; }

.bp3-rtl {
  text-align: right; }

.bp3-dark {
  color: #f5f8fa; }

:focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.bp3-focus-disabled :focus {
  outline: none !important; }

.bp3-focus-disabled :focus ~ .bp3-control-indicator {
  outline: none !important; }

.bp3-alert {
  max-width: 400px;
  padding: 20px; }

.bp3-alert-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.bp3-alert-body .bp3-icon {
  margin-top: 0;
  margin-right: 20px;
  font-size: 40px; }

.bp3-alert-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: 10px; }

.bp3-alert-footer .bp3-button {
  margin-left: 10px; }

.bp3-breadcrumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  cursor: default;
  height: 30px;
  padding: 0;
  list-style: none; }

.bp3-breadcrumbs > li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.bp3-breadcrumbs > li::after {
  display: block;
  margin: 0 5px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 0 0-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235C7080'/%3e%3c/svg%3e");
  width: 16px;
  height: 16px;
  content: ""; }

.bp3-breadcrumbs > li:last-of-type::after {
  display: none; }

.bp3-breadcrumb,
.bp3-breadcrumb-current,
.bp3-breadcrumbs-collapsed {
  display: inline-block;
  font-size: 16px; }

.bp3-breadcrumb,
.bp3-breadcrumbs-collapsed {
  color: #5c7080; }

.bp3-breadcrumb:hover {
  text-decoration: none; }

.bp3-breadcrumb.bp3-disabled {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-breadcrumb-current {
  color: inherit;
  font-weight: 600; }

.bp3-breadcrumb-current .bp3-input {
  vertical-align: baseline;
  font-size: inherit;
  font-weight: inherit; }

.bp3-breadcrumbs-collapsed {
  margin-right: 2px;
  border: none;
  border-radius: 3px;
  background: #ced9e0;
  cursor: pointer;
  padding: 1px 5px;
  vertical-align: text-bottom; }

.bp3-breadcrumbs-collapsed::before {
  display: block;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%235C7080'%3e%3ccircle cx='2' cy='8.03' r='2'/%3e%3ccircle cx='14' cy='8.03' r='2'/%3e%3ccircle cx='8' cy='8.03' r='2'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
  width: 16px;
  height: 16px;
  content: ""; }

.bp3-breadcrumbs-collapsed:hover {
  background: #bfccd6;
  text-decoration: none;
  color: #182026; }

.bp3-dark .bp3-breadcrumb,
.bp3-dark .bp3-breadcrumbs-collapsed {
  color: #a7b6c2; }

.bp3-dark .bp3-breadcrumbs > li::after {
  color: #a7b6c2; }

.bp3-dark .bp3-breadcrumb.bp3-disabled {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-breadcrumb-current {
  color: #f5f8fa; }

.bp3-dark .bp3-breadcrumbs-collapsed {
  background: rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-breadcrumbs-collapsed:hover {
  background: rgba(16, 22, 26, 0.6);
  color: #f5f8fa; }

.bp3-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px; }

.bp3-button > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-button > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-button::before,
.bp3-button > * {
  margin-right: 7px; }

.bp3-button:empty::before,
.bp3-button > :last-child {
  margin-right: 0; }

.bp3-button:empty {
  padding: 0 !important; }

.bp3-button:disabled, .bp3-button.bp3-disabled {
  cursor: not-allowed; }

.bp3-button.bp3-fill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.bp3-button.bp3-align-right,
.bp3-align-right .bp3-button {
  text-align: right; }

.bp3-button:not([class*="bp3-intent-"]) {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026; }

.bp3-button:not([class*="bp3-intent-"]):hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #ebf1f5; }

.bp3-button:not([class*="bp3-intent-"]):active, .bp3-button:not([class*="bp3-intent-"]).bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #d8e1e8;
  background-image: none; }

.bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  background-image: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active:hover, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active:hover {
  background: rgba(206, 217, 224, 0.7); }

.bp3-button.bp3-intent-primary {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #137cbd;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  color: #ffffff; }

.bp3-button.bp3-intent-primary:hover, .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
  color: #ffffff; }

.bp3-button.bp3-intent-primary:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #106ba3; }

.bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #0e5a8a;
  background-image: none; }

.bp3-button.bp3-intent-primary:disabled, .bp3-button.bp3-intent-primary.bp3-disabled {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(19, 124, 189, 0.5);
  background-image: none;
  color: rgba(255, 255, 255, 0.6); }

.bp3-button.bp3-intent-success {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #0f9960;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  color: #ffffff; }

.bp3-button.bp3-intent-success:hover, .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
  color: #ffffff; }

.bp3-button.bp3-intent-success:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #0d8050; }

.bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #0a6640;
  background-image: none; }

.bp3-button.bp3-intent-success:disabled, .bp3-button.bp3-intent-success.bp3-disabled {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(15, 153, 96, 0.5);
  background-image: none;
  color: rgba(255, 255, 255, 0.6); }

.bp3-button.bp3-intent-warning {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #d9822b;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  color: #ffffff; }

.bp3-button.bp3-intent-warning:hover, .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
  color: #ffffff; }

.bp3-button.bp3-intent-warning:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #bf7326; }

.bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #a66321;
  background-image: none; }

.bp3-button.bp3-intent-warning:disabled, .bp3-button.bp3-intent-warning.bp3-disabled {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(217, 130, 43, 0.5);
  background-image: none;
  color: rgba(255, 255, 255, 0.6); }

.bp3-button.bp3-intent-danger {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #db3737;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  color: #ffffff; }

.bp3-button.bp3-intent-danger:hover, .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
  color: #ffffff; }

.bp3-button.bp3-intent-danger:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #c23030; }

.bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #a82a2a;
  background-image: none; }

.bp3-button.bp3-intent-danger:disabled, .bp3-button.bp3-intent-danger.bp3-disabled {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(219, 55, 55, 0.5);
  background-image: none;
  color: rgba(255, 255, 255, 0.6); }

.bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
  stroke: #ffffff; }

.bp3-button.bp3-large,
.bp3-large .bp3-button {
  min-width: 40px;
  min-height: 40px;
  padding: 5px 15px;
  font-size: 16px; }

.bp3-button.bp3-large::before,
.bp3-button.bp3-large > *,
.bp3-large .bp3-button::before,
.bp3-large .bp3-button > * {
  margin-right: 10px; }

.bp3-button.bp3-large:empty::before,
.bp3-button.bp3-large > :last-child,
.bp3-large .bp3-button:empty::before,
.bp3-large .bp3-button > :last-child {
  margin-right: 0; }

.bp3-button.bp3-small,
.bp3-small .bp3-button {
  min-width: 24px;
  min-height: 24px;
  padding: 0 7px; }

.bp3-button.bp3-loading {
  position: relative; }

.bp3-button.bp3-loading[class*="bp3-icon-"]::before {
  visibility: hidden; }

.bp3-button.bp3-loading .bp3-button-spinner {
  position: absolute;
  margin: 0; }

.bp3-button.bp3-loading > :not(.bp3-button-spinner) {
  visibility: hidden; }

.bp3-button[class*="bp3-icon-"]::before {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5c7080; }

.bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large {
  color: #5c7080; }

.bp3-button .bp3-icon.bp3-align-right, .bp3-button .bp3-icon-standard.bp3-align-right, .bp3-button .bp3-icon-large.bp3-align-right {
  margin-left: 7px; }

.bp3-button .bp3-icon:first-child:last-child,
.bp3-button .bp3-spinner + .bp3-icon:last-child {
  margin: 0 -7px; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]) {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.05)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover, .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
  color: #f5f8fa; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #30404d; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #202b33;
  background-image: none; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(57, 75, 89, 0.5);
  background-image: none;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active {
  background: rgba(57, 75, 89, 0.7); }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-button-spinner .bp3-spinner-head {
  background: rgba(16, 22, 26, 0.5);
  stroke: #8a9ba8; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
  color: #a7b6c2; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large {
  color: #a7b6c2; }

.bp3-dark .bp3-button[class*="bp3-intent-"] {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-button[class*="bp3-intent-"]:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-button[class*="bp3-intent-"]:active, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-active {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }

.bp3-dark .bp3-button[class*="bp3-intent-"]:disabled, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  color: rgba(255, 255, 255, 0.3); }

.bp3-dark .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
  stroke: #8a9ba8; }

.bp3-button:disabled::before,
.bp3-button:disabled .bp3-icon, .bp3-button:disabled .bp3-icon-standard, .bp3-button:disabled .bp3-icon-large, .bp3-button.bp3-disabled::before,
.bp3-button.bp3-disabled .bp3-icon, .bp3-button.bp3-disabled .bp3-icon-standard, .bp3-button.bp3-disabled .bp3-icon-large, .bp3-button[class*="bp3-intent-"]::before,
.bp3-button[class*="bp3-intent-"] .bp3-icon, .bp3-button[class*="bp3-intent-"] .bp3-icon-standard, .bp3-button[class*="bp3-intent-"] .bp3-icon-large {
  color: inherit !important; }

.bp3-button.bp3-minimal {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-button.bp3-minimal:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(167, 182, 194, 0.3);
  text-decoration: none;
  color: #182026; }

.bp3-button.bp3-minimal:active, .bp3-button.bp3-minimal.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(115, 134, 148, 0.3);
  color: #182026; }

.bp3-button.bp3-minimal:disabled, .bp3-button.bp3-minimal:disabled:hover, .bp3-button.bp3-minimal.bp3-disabled, .bp3-button.bp3-minimal.bp3-disabled:hover {
  background: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-button.bp3-minimal:disabled.bp3-active, .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
  background: rgba(115, 134, 148, 0.3); }

.bp3-dark .bp3-button.bp3-minimal {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: inherit; }

.bp3-dark .bp3-button.bp3-minimal:hover, .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-dark .bp3-button.bp3-minimal:hover {
  background: rgba(138, 155, 168, 0.15); }

.bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
  background: rgba(138, 155, 168, 0.3);
  color: #f5f8fa; }

.bp3-dark .bp3-button.bp3-minimal:disabled, .bp3-dark .bp3-button.bp3-minimal:disabled:hover, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover {
  background: none;
  cursor: not-allowed;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
  background: rgba(138, 155, 168, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-primary {
  color: #106ba3; }

.bp3-button.bp3-minimal.bp3-intent-primary:hover, .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #106ba3; }

.bp3-button.bp3-minimal.bp3-intent-primary:hover {
  background: rgba(19, 124, 189, 0.15);
  color: #106ba3; }

.bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
  background: rgba(19, 124, 189, 0.3);
  color: #106ba3; }

.bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
  background: none;
  color: rgba(16, 107, 163, 0.5); }

.bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
  background: rgba(19, 124, 189, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
  stroke: #106ba3; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary {
  color: #48aff0; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:hover {
  background: rgba(19, 124, 189, 0.2);
  color: #48aff0; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
  background: rgba(19, 124, 189, 0.3);
  color: #48aff0; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
  background: none;
  color: rgba(72, 175, 240, 0.5); }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
  background: rgba(19, 124, 189, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-success {
  color: #0d8050; }

.bp3-button.bp3-minimal.bp3-intent-success:hover, .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #0d8050; }

.bp3-button.bp3-minimal.bp3-intent-success:hover {
  background: rgba(15, 153, 96, 0.15);
  color: #0d8050; }

.bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
  background: rgba(15, 153, 96, 0.3);
  color: #0d8050; }

.bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
  background: none;
  color: rgba(13, 128, 80, 0.5); }

.bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
  background: rgba(15, 153, 96, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
  stroke: #0d8050; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-success {
  color: #3dcc91; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:hover {
  background: rgba(15, 153, 96, 0.2);
  color: #3dcc91; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
  background: rgba(15, 153, 96, 0.3);
  color: #3dcc91; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
  background: none;
  color: rgba(61, 204, 145, 0.5); }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
  background: rgba(15, 153, 96, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-warning {
  color: #bf7326; }

.bp3-button.bp3-minimal.bp3-intent-warning:hover, .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #bf7326; }

.bp3-button.bp3-minimal.bp3-intent-warning:hover {
  background: rgba(217, 130, 43, 0.15);
  color: #bf7326; }

.bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
  background: rgba(217, 130, 43, 0.3);
  color: #bf7326; }

.bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
  background: none;
  color: rgba(191, 115, 38, 0.5); }

.bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
  background: rgba(217, 130, 43, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
  stroke: #bf7326; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning {
  color: #ffb366; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:hover {
  background: rgba(217, 130, 43, 0.2);
  color: #ffb366; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
  background: rgba(217, 130, 43, 0.3);
  color: #ffb366; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
  background: none;
  color: rgba(255, 179, 102, 0.5); }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
  background: rgba(217, 130, 43, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-danger {
  color: #c23030; }

.bp3-button.bp3-minimal.bp3-intent-danger:hover, .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #c23030; }

.bp3-button.bp3-minimal.bp3-intent-danger:hover {
  background: rgba(219, 55, 55, 0.15);
  color: #c23030; }

.bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
  background: rgba(219, 55, 55, 0.3);
  color: #c23030; }

.bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
  background: none;
  color: rgba(194, 48, 48, 0.5); }

.bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
  background: rgba(219, 55, 55, 0.3); }

.bp3-button.bp3-minimal.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
  stroke: #c23030; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger {
  color: #ff7373; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:hover {
  background: rgba(219, 55, 55, 0.2);
  color: #ff7373; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
  background: rgba(219, 55, 55, 0.3);
  color: #ff7373; }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
  background: none;
  color: rgba(255, 115, 115, 0.5); }

.bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
  background: rgba(219, 55, 55, 0.3); }

a.bp3-button {
  text-align: center;
  text-decoration: none;
  -webkit-transition: none;
  transition: none; }

a.bp3-button, a.bp3-button:hover, a.bp3-button:active {
  color: #182026; }

a.bp3-button.bp3-disabled {
  color: rgba(92, 112, 128, 0.6); }

.bp3-button-text {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.bp3-button.bp3-align-left .bp3-button-text, .bp3-button.bp3-align-right .bp3-button-text,
.bp3-button-group.bp3-align-left .bp3-button-text,
.bp3-button-group.bp3-align-right .bp3-button-text {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.bp3-button-group {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.bp3-button-group .bp3-button {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  z-index: 4; }

.bp3-button-group .bp3-button:focus {
  z-index: 5; }

.bp3-button-group .bp3-button:hover {
  z-index: 6; }

.bp3-button-group .bp3-button:active, .bp3-button-group .bp3-button.bp3-active {
  z-index: 7; }

.bp3-button-group .bp3-button:disabled, .bp3-button-group .bp3-button.bp3-disabled {
  z-index: 3; }

.bp3-button-group .bp3-button[class*="bp3-intent-"] {
  z-index: 9; }

.bp3-button-group .bp3-button[class*="bp3-intent-"]:focus {
  z-index: 10; }

.bp3-button-group .bp3-button[class*="bp3-intent-"]:hover {
  z-index: 11; }

.bp3-button-group .bp3-button[class*="bp3-intent-"]:active, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-active {
  z-index: 12; }

.bp3-button-group .bp3-button[class*="bp3-intent-"]:disabled, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-disabled {
  z-index: 8; }

.bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:first-child) .bp3-button,
.bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
.bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.bp3-button-group.bp3-minimal .bp3-button {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-button-group.bp3-minimal .bp3-button:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(167, 182, 194, 0.3);
  text-decoration: none;
  color: #182026; }

.bp3-button-group.bp3-minimal .bp3-button:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(115, 134, 148, 0.3);
  color: #182026; }

.bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
  background: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
  background: rgba(115, 134, 148, 0.3); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: inherit; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover {
  background: rgba(138, 155, 168, 0.15); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
  background: rgba(138, 155, 168, 0.3);
  color: #f5f8fa; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
  background: none;
  cursor: not-allowed;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
  background: rgba(138, 155, 168, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
  color: #106ba3; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #106ba3; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
  background: rgba(19, 124, 189, 0.15);
  color: #106ba3; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
  background: rgba(19, 124, 189, 0.3);
  color: #106ba3; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
  background: none;
  color: rgba(16, 107, 163, 0.5); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
  background: rgba(19, 124, 189, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
  stroke: #106ba3; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
  color: #48aff0; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
  background: rgba(19, 124, 189, 0.2);
  color: #48aff0; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
  background: rgba(19, 124, 189, 0.3);
  color: #48aff0; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
  background: none;
  color: rgba(72, 175, 240, 0.5); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
  background: rgba(19, 124, 189, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
  color: #0d8050; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #0d8050; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
  background: rgba(15, 153, 96, 0.15);
  color: #0d8050; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
  background: rgba(15, 153, 96, 0.3);
  color: #0d8050; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
  background: none;
  color: rgba(13, 128, 80, 0.5); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
  background: rgba(15, 153, 96, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
  stroke: #0d8050; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
  color: #3dcc91; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
  background: rgba(15, 153, 96, 0.2);
  color: #3dcc91; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
  background: rgba(15, 153, 96, 0.3);
  color: #3dcc91; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
  background: none;
  color: rgba(61, 204, 145, 0.5); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
  background: rgba(15, 153, 96, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
  color: #bf7326; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #bf7326; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
  background: rgba(217, 130, 43, 0.15);
  color: #bf7326; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
  background: rgba(217, 130, 43, 0.3);
  color: #bf7326; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
  background: none;
  color: rgba(191, 115, 38, 0.5); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
  background: rgba(217, 130, 43, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
  stroke: #bf7326; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
  color: #ffb366; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
  background: rgba(217, 130, 43, 0.2);
  color: #ffb366; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
  background: rgba(217, 130, 43, 0.3);
  color: #ffb366; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
  background: none;
  color: rgba(255, 179, 102, 0.5); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
  background: rgba(217, 130, 43, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
  color: #c23030; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #c23030; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
  background: rgba(219, 55, 55, 0.15);
  color: #c23030; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
  background: rgba(219, 55, 55, 0.3);
  color: #c23030; }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
  background: none;
  color: rgba(194, 48, 48, 0.5); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
  background: rgba(219, 55, 55, 0.3); }

.bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
  stroke: #c23030; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
  color: #ff7373; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
  background: rgba(219, 55, 55, 0.2);
  color: #ff7373; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
  background: rgba(219, 55, 55, 0.3);
  color: #ff7373; }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
  background: none;
  color: rgba(255, 115, 115, 0.5); }

.bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
  background: rgba(219, 55, 55, 0.3); }

.bp3-button-group .bp3-popover-wrapper,
.bp3-button-group .bp3-popover-target {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.bp3-button-group.bp3-fill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.bp3-button-group .bp3-button.bp3-fill,
.bp3-button-group.bp3-fill .bp3-button:not(.bp3-fixed) {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.bp3-button-group.bp3-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  vertical-align: top; }

.bp3-button-group.bp3-vertical.bp3-fill {
  width: unset;
  height: 100%; }

.bp3-button-group.bp3-vertical .bp3-button {
  margin-right: 0 !important;
  width: 100%; }

.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:first-child .bp3-button,
.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:first-child {
  border-radius: 3px 3px 0 0; }

.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:last-child .bp3-button,
.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:last-child {
  border-radius: 0 0 3px 3px; }

.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:not(:last-child) {
  margin-bottom: -1px; }

.bp3-button-group.bp3-align-left .bp3-button {
  text-align: left; }

.bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
.bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
  margin-right: 1px; }

.bp3-dark .bp3-button-group.bp3-vertical > .bp3-popover-wrapper:not(:last-child) .bp3-button,
.bp3-dark .bp3-button-group.bp3-vertical > .bp3-button:not(:last-child) {
  margin-bottom: 1px; }

.bp3-callout {
  line-height: 1.5;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  background-color: rgba(138, 155, 168, 0.15);
  width: 100%;
  padding: 10px 12px 9px; }

.bp3-callout[class*="bp3-icon-"] {
  padding-left: 40px; }

.bp3-callout[class*="bp3-icon-"]::before {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 10px;
  left: 10px;
  color: #5c7080; }

.bp3-callout.bp3-callout-icon {
  padding-left: 40px; }

.bp3-callout.bp3-callout-icon > .bp3-icon:first-child {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #5c7080; }

.bp3-callout .bp3-heading {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 20px; }

.bp3-callout .bp3-heading:last-child {
  margin-bottom: 0; }

.bp3-dark .bp3-callout {
  background-color: rgba(138, 155, 168, 0.2); }

.bp3-dark .bp3-callout[class*="bp3-icon-"]::before {
  color: #a7b6c2; }

.bp3-callout.bp3-intent-primary {
  background-color: rgba(19, 124, 189, 0.15); }

.bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
.bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
.bp3-callout.bp3-intent-primary .bp3-heading {
  color: #106ba3; }

.bp3-dark .bp3-callout.bp3-intent-primary {
  background-color: rgba(19, 124, 189, 0.25); }

.bp3-dark .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
.bp3-dark .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
.bp3-dark .bp3-callout.bp3-intent-primary .bp3-heading {
  color: #48aff0; }

.bp3-callout.bp3-intent-success {
  background-color: rgba(15, 153, 96, 0.15); }

.bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
.bp3-callout.bp3-intent-success > .bp3-icon:first-child,
.bp3-callout.bp3-intent-success .bp3-heading {
  color: #0d8050; }

.bp3-dark .bp3-callout.bp3-intent-success {
  background-color: rgba(15, 153, 96, 0.25); }

.bp3-dark .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
.bp3-dark .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
.bp3-dark .bp3-callout.bp3-intent-success .bp3-heading {
  color: #3dcc91; }

.bp3-callout.bp3-intent-warning {
  background-color: rgba(217, 130, 43, 0.15); }

.bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
.bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
.bp3-callout.bp3-intent-warning .bp3-heading {
  color: #bf7326; }

.bp3-dark .bp3-callout.bp3-intent-warning {
  background-color: rgba(217, 130, 43, 0.25); }

.bp3-dark .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
.bp3-dark .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
.bp3-dark .bp3-callout.bp3-intent-warning .bp3-heading {
  color: #ffb366; }

.bp3-callout.bp3-intent-danger {
  background-color: rgba(219, 55, 55, 0.15); }

.bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
.bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
.bp3-callout.bp3-intent-danger .bp3-heading {
  color: #c23030; }

.bp3-dark .bp3-callout.bp3-intent-danger {
  background-color: rgba(219, 55, 55, 0.25); }

.bp3-dark .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
.bp3-dark .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
.bp3-dark .bp3-callout.bp3-intent-danger .bp3-heading {
  color: #ff7373; }

.bp3-running-text .bp3-callout {
  margin: 20px 0; }

.bp3-card {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  background-color: #ffffff;
  padding: 20px;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-card.bp3-dark,
.bp3-dark .bp3-card {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  background-color: #30404d; }

.bp3-elevation-0 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.bp3-elevation-0.bp3-dark,
.bp3-dark .bp3-elevation-0 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.bp3-elevation-1 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-elevation-1.bp3-dark,
.bp3-dark .bp3-elevation-1 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-elevation-2 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2); }

.bp3-elevation-2.bp3-dark,
.bp3-dark .bp3-elevation-2 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4); }

.bp3-elevation-3 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }

.bp3-elevation-3.bp3-dark,
.bp3-dark .bp3-elevation-3 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-elevation-4 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2); }

.bp3-elevation-4.bp3-dark,
.bp3-dark .bp3-elevation-4 {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4); }

.bp3-card.bp3-interactive:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  cursor: pointer; }

.bp3-card.bp3-interactive:hover.bp3-dark,
.bp3-dark .bp3-card.bp3-interactive:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-card.bp3-interactive:active {
  opacity: 0.9;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  -webkit-transition-duration: 0;
  transition-duration: 0; }

.bp3-card.bp3-interactive:active.bp3-dark,
.bp3-dark .bp3-card.bp3-interactive:active {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-collapse {
  height: 0;
  overflow-y: hidden;
  -webkit-transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-collapse .bp3-collapse-body {
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-collapse .bp3-collapse-body[aria-hidden="true"] {
  display: none; }

.bp3-context-menu .bp3-popover-target {
  display: block; }

.bp3-context-menu-popover-target {
  position: fixed; }

.bp3-divider {
  margin: 5px;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  border-bottom: 1px solid rgba(16, 22, 26, 0.15); }

.bp3-dark .bp3-divider {
  border-color: rgba(16, 22, 26, 0.4); }

.bp3-dialog-container {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-dialog-container.bp3-overlay-enter > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear > .bp3-dialog {
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5); }

.bp3-dialog-container.bp3-overlay-enter-active > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear-active > .bp3-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-dialog-container.bp3-overlay-exit > .bp3-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-dialog-container.bp3-overlay-exit-active > .bp3-dialog {
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-dialog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 30px 0;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background: #ebf1f5;
  width: 500px;
  padding-bottom: 20px;
  pointer-events: all;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.bp3-dialog:focus {
  outline: 0; }

.bp3-dialog.bp3-dark,
.bp3-dark .bp3-dialog {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
  background: #293742;
  color: #f5f8fa; }

.bp3-dialog-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  background: #ffffff;
  min-height: 40px;
  padding-right: 5px;
  padding-left: 20px; }

.bp3-dialog-header .bp3-icon-large,
.bp3-dialog-header .bp3-icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 10px;
  color: #5c7080; }

.bp3-dialog-header .bp3-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  line-height: inherit; }

.bp3-dialog-header .bp3-heading:last-child {
  margin-right: 20px; }

.bp3-dark .bp3-dialog-header {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
  background: #30404d; }

.bp3-dark .bp3-dialog-header .bp3-icon-large,
.bp3-dark .bp3-dialog-header .bp3-icon {
  color: #a7b6c2; }

.bp3-dialog-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px; }

.bp3-dialog-footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 20px; }

.bp3-dialog-footer-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.bp3-dialog-footer-actions .bp3-button {
  margin-left: 10px; }

.bp3-drawer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  padding: 0; }

.bp3-drawer:focus {
  outline: 0; }

.bp3-drawer.bp3-position-top {
  top: 0;
  right: 0;
  left: 0;
  height: 50%; }

.bp3-drawer.bp3-position-top.bp3-overlay-enter, .bp3-drawer.bp3-position-top.bp3-overlay-appear {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.bp3-drawer.bp3-position-top.bp3-overlay-enter-active, .bp3-drawer.bp3-position-top.bp3-overlay-appear-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-top.bp3-overlay-exit {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.bp3-drawer.bp3-position-top.bp3-overlay-exit-active {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-bottom {
  right: 0;
  bottom: 0;
  left: 0;
  height: 50%; }

.bp3-drawer.bp3-position-bottom.bp3-overlay-enter, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear {
  -webkit-transform: translateY(100%);
  transform: translateY(100%); }

.bp3-drawer.bp3-position-bottom.bp3-overlay-enter-active, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-bottom.bp3-overlay-exit {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.bp3-drawer.bp3-position-bottom.bp3-overlay-exit-active {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-left {
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%; }

.bp3-drawer.bp3-position-left.bp3-overlay-enter, .bp3-drawer.bp3-position-left.bp3-overlay-appear {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.bp3-drawer.bp3-position-left.bp3-overlay-enter-active, .bp3-drawer.bp3-position-left.bp3-overlay-appear-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-left.bp3-overlay-exit {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.bp3-drawer.bp3-position-left.bp3-overlay-exit-active {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-right {
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%; }

.bp3-drawer.bp3-position-right.bp3-overlay-enter, .bp3-drawer.bp3-position-right.bp3-overlay-appear {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.bp3-drawer.bp3-position-right.bp3-overlay-enter-active, .bp3-drawer.bp3-position-right.bp3-overlay-appear-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-position-right.bp3-overlay-exit {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.bp3-drawer.bp3-position-right.bp3-overlay-exit-active {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical) {
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%; }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical).bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical).bp3-overlay-appear {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical).bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical).bp3-overlay-appear-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical).bp3-overlay-exit {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right):not(.bp3-vertical).bp3-overlay-exit-active {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical {
  right: 0;
  bottom: 0;
  left: 0;
  height: 50%; }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical.bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical.bp3-overlay-appear {
  -webkit-transform: translateY(100%);
  transform: translateY(100%); }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical.bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical.bp3-overlay-appear-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical.bp3-overlay-exit {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
.bp3-position-right).bp3-vertical.bp3-overlay-exit-active {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-drawer.bp3-dark,
.bp3-dark .bp3-drawer {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
  background: #30404d;
  color: #f5f8fa; }

.bp3-drawer-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  min-height: 40px;
  padding: 5px;
  padding-left: 20px; }

.bp3-drawer-header .bp3-icon-large,
.bp3-drawer-header .bp3-icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 10px;
  color: #5c7080; }

.bp3-drawer-header .bp3-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  line-height: inherit; }

.bp3-drawer-header .bp3-heading:last-child {
  margin-right: 20px; }

.bp3-dark .bp3-drawer-header {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-drawer-header .bp3-icon-large,
.bp3-dark .bp3-drawer-header .bp3-icon {
  color: #a7b6c2; }

.bp3-drawer-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: auto;
  line-height: 18px; }

.bp3-drawer-footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  -webkit-box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  padding: 10px 20px; }

.bp3-dark .bp3-drawer-footer {
  -webkit-box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4); }

.bp3-editable-text {
  display: inline-block;
  position: relative;
  cursor: text;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap; }

.bp3-editable-text::before {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border-radius: 3px;
  content: "";
  -webkit-transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-editable-text:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15); }

.bp3-editable-text.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #ffffff; }

.bp3-editable-text.bp3-disabled::before {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-editable-text.bp3-intent-primary .bp3-editable-text-input,
.bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
  color: #137cbd; }

.bp3-editable-text.bp3-intent-primary:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4); }

.bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-editable-text.bp3-intent-success .bp3-editable-text-input,
.bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
  color: #0f9960; }

.bp3-editable-text.bp3-intent-success:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4);
  box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }

.bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-editable-text.bp3-intent-warning .bp3-editable-text-input,
.bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
  color: #d9822b; }

.bp3-editable-text.bp3-intent-warning:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4);
  box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }

.bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-editable-text.bp3-intent-danger .bp3-editable-text-input,
.bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
  color: #db3737; }

.bp3-editable-text.bp3-intent-danger:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4);
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }

.bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-dark .bp3-editable-text:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }

.bp3-dark .bp3-editable-text.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background-color: rgba(16, 22, 26, 0.3); }

.bp3-dark .bp3-editable-text.bp3-disabled::before {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
  color: #48aff0; }

.bp3-dark .bp3-editable-text.bp3-intent-primary:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(72, 175, 240, 0), 0 0 0 0 rgba(72, 175, 240, 0), inset 0 0 0 1px rgba(72, 175, 240, 0.4);
  box-shadow: 0 0 0 0 rgba(72, 175, 240, 0), 0 0 0 0 rgba(72, 175, 240, 0), inset 0 0 0 1px rgba(72, 175, 240, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #48aff0, 0 0 0 3px rgba(72, 175, 240, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #48aff0, 0 0 0 3px rgba(72, 175, 240, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
  color: #3dcc91; }

.bp3-dark .bp3-editable-text.bp3-intent-success:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4);
  box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
  color: #ffb366; }

.bp3-dark .bp3-editable-text.bp3-intent-warning:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4);
  box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
  color: #ff7373; }

.bp3-dark .bp3-editable-text.bp3-intent-danger:hover::before {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4);
  box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4); }

.bp3-dark .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
  -webkit-box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-editable-text-input,
.bp3-editable-text-content {
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none; }

.bp3-editable-text-input {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  width: 100%;
  padding: 0;
  white-space: pre-wrap; }

.bp3-editable-text-input::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-editable-text-input::-ms-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-editable-text-input::placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-editable-text-input:focus {
  outline: none; }

.bp3-editable-text-input::-ms-clear {
  display: none; }

.bp3-editable-text-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }

.bp3-editable-text-editing > .bp3-editable-text-content {
  position: absolute;
  left: 0;
  visibility: hidden; }

.bp3-editable-text-placeholder > .bp3-editable-text-content {
  color: rgba(92, 112, 128, 0.6); }

.bp3-dark .bp3-editable-text-placeholder > .bp3-editable-text-content {
  color: rgba(167, 182, 194, 0.6); }

.bp3-editable-text.bp3-multiline {
  display: block; }

.bp3-editable-text.bp3-multiline .bp3-editable-text-content {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word; }

.bp3-control-group {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.bp3-control-group > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-control-group > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-control-group .bp3-button,
.bp3-control-group .bp3-html-select,
.bp3-control-group .bp3-input,
.bp3-control-group .bp3-select {
  position: relative; }

.bp3-control-group .bp3-input {
  z-index: 2;
  border-radius: inherit; }

.bp3-control-group .bp3-input:focus {
  z-index: 14;
  border-radius: 3px; }

.bp3-control-group .bp3-input[class*="bp3-intent"] {
  z-index: 13; }

.bp3-control-group .bp3-input[class*="bp3-intent"]:focus {
  z-index: 15; }

.bp3-control-group .bp3-input[readonly], .bp3-control-group .bp3-input:disabled, .bp3-control-group .bp3-input.bp3-disabled {
  z-index: 1; }

.bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input {
  z-index: 13; }

.bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input:focus {
  z-index: 15; }

.bp3-control-group .bp3-button,
.bp3-control-group .bp3-html-select select,
.bp3-control-group .bp3-select select {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 4;
  border-radius: inherit; }

.bp3-control-group .bp3-button:focus,
.bp3-control-group .bp3-html-select select:focus,
.bp3-control-group .bp3-select select:focus {
  z-index: 5; }

.bp3-control-group .bp3-button:hover,
.bp3-control-group .bp3-html-select select:hover,
.bp3-control-group .bp3-select select:hover {
  z-index: 6; }

.bp3-control-group .bp3-button:active,
.bp3-control-group .bp3-html-select select:active,
.bp3-control-group .bp3-select select:active {
  z-index: 7; }

.bp3-control-group .bp3-button[readonly], .bp3-control-group .bp3-button:disabled, .bp3-control-group .bp3-button.bp3-disabled,
.bp3-control-group .bp3-html-select select[readonly],
.bp3-control-group .bp3-html-select select:disabled,
.bp3-control-group .bp3-html-select select.bp3-disabled,
.bp3-control-group .bp3-select select[readonly],
.bp3-control-group .bp3-select select:disabled,
.bp3-control-group .bp3-select select.bp3-disabled {
  z-index: 3; }

.bp3-control-group .bp3-button[class*="bp3-intent"],
.bp3-control-group .bp3-html-select select[class*="bp3-intent"],
.bp3-control-group .bp3-select select[class*="bp3-intent"] {
  z-index: 9; }

.bp3-control-group .bp3-button[class*="bp3-intent"]:focus,
.bp3-control-group .bp3-html-select select[class*="bp3-intent"]:focus,
.bp3-control-group .bp3-select select[class*="bp3-intent"]:focus {
  z-index: 10; }

.bp3-control-group .bp3-button[class*="bp3-intent"]:hover,
.bp3-control-group .bp3-html-select select[class*="bp3-intent"]:hover,
.bp3-control-group .bp3-select select[class*="bp3-intent"]:hover {
  z-index: 11; }

.bp3-control-group .bp3-button[class*="bp3-intent"]:active,
.bp3-control-group .bp3-html-select select[class*="bp3-intent"]:active,
.bp3-control-group .bp3-select select[class*="bp3-intent"]:active {
  z-index: 12; }

.bp3-control-group .bp3-button[class*="bp3-intent"][readonly], .bp3-control-group .bp3-button[class*="bp3-intent"]:disabled, .bp3-control-group .bp3-button[class*="bp3-intent"].bp3-disabled,
.bp3-control-group .bp3-html-select select[class*="bp3-intent"][readonly],
.bp3-control-group .bp3-html-select select[class*="bp3-intent"]:disabled,
.bp3-control-group .bp3-html-select select[class*="bp3-intent"].bp3-disabled,
.bp3-control-group .bp3-select select[class*="bp3-intent"][readonly],
.bp3-control-group .bp3-select select[class*="bp3-intent"]:disabled,
.bp3-control-group .bp3-select select[class*="bp3-intent"].bp3-disabled {
  z-index: 8; }

.bp3-control-group .bp3-input-group > .bp3-icon,
.bp3-control-group .bp3-input-group > .bp3-button,
.bp3-control-group .bp3-input-group > .bp3-input-action {
  z-index: 16; }

.bp3-control-group .bp3-select::after,
.bp3-control-group .bp3-html-select::after,
.bp3-control-group .bp3-select > .bp3-icon,
.bp3-control-group .bp3-html-select > .bp3-icon {
  z-index: 17; }

.bp3-control-group:not(.bp3-vertical) > * {
  margin-right: -1px; }

.bp3-dark .bp3-control-group:not(.bp3-vertical) > * {
  margin-right: 0; }

.bp3-dark .bp3-control-group:not(.bp3-vertical) > .bp3-button + .bp3-button {
  margin-left: 1px; }

.bp3-control-group .bp3-popover-wrapper,
.bp3-control-group .bp3-popover-target {
  border-radius: inherit; }

.bp3-control-group > :first-child {
  border-radius: 3px 0 0 3px; }

.bp3-control-group > :last-child {
  margin-right: 0;
  border-radius: 0 3px 3px 0; }

.bp3-control-group > :only-child {
  margin-right: 0;
  border-radius: 3px; }

.bp3-control-group .bp3-input-group .bp3-button {
  border-radius: 3px; }

.bp3-control-group > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.bp3-control-group.bp3-fill > *:not(.bp3-fixed) {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.bp3-control-group.bp3-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.bp3-control-group.bp3-vertical > * {
  margin-top: -1px; }

.bp3-control-group.bp3-vertical > :first-child {
  margin-top: 0;
  border-radius: 3px 3px 0 0; }

.bp3-control-group.bp3-vertical > :last-child {
  border-radius: 0 0 3px 3px; }

.bp3-control {
  display: block;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: none; }

.bp3-control input:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #137cbd;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  color: #ffffff; }

.bp3-control:hover input:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #106ba3; }

.bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background: #0e5a8a; }

.bp3-control input:disabled:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(19, 124, 189, 0.5); }

.bp3-dark .bp3-control input:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-control:hover input:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #106ba3; }

.bp3-dark .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #0e5a8a; }

.bp3-dark .bp3-control input:disabled:checked ~ .bp3-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(14, 90, 138, 0.5); }

.bp3-control:not(.bp3-align-right) {
  padding-left: 26px; }

.bp3-control:not(.bp3-align-right) .bp3-control-indicator {
  margin-left: -26px; }

.bp3-control.bp3-align-right {
  padding-right: 26px; }

.bp3-control.bp3-align-right .bp3-control-indicator {
  margin-right: -26px; }

.bp3-control.bp3-disabled {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-control.bp3-inline {
  display: inline-block;
  margin-right: 20px; }

.bp3-control input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1; }

.bp3-control .bp3-control-indicator {
  display: inline-block;
  position: relative;
  margin-top: -3px;
  margin-right: 10px;
  border: none;
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #f5f8fa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  cursor: pointer;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-control .bp3-control-indicator::before {
  display: block;
  width: 1em;
  height: 1em;
  content: ""; }

.bp3-control:hover .bp3-control-indicator {
  background-color: #ebf1f5; }

.bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background: #d8e1e8; }

.bp3-control input:disabled ~ .bp3-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(206, 217, 224, 0.5);
  cursor: not-allowed; }

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.bp3-control.bp3-align-right .bp3-control-indicator {
  float: right;
  margin-top: 1px;
  margin-left: 10px; }

.bp3-control.bp3-large {
  font-size: 16px; }

.bp3-control.bp3-large:not(.bp3-align-right) {
  padding-left: 30px; }

.bp3-control.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
  margin-left: -30px; }

.bp3-control.bp3-large.bp3-align-right {
  padding-right: 30px; }

.bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
  margin-right: -30px; }

.bp3-control.bp3-large .bp3-control-indicator {
  font-size: 20px; }

.bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
  margin-top: 0; }

.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #137cbd;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  color: #ffffff; }

.bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  background-color: #106ba3; }

.bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background: #0e5a8a; }

.bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(19, 124, 189, 0.5); }

.bp3-dark .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #106ba3; }

.bp3-dark .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #0e5a8a; }

.bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(14, 90, 138, 0.5); }

.bp3-control.bp3-checkbox .bp3-control-indicator {
  border-radius: 3px; }

.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='white'/%3e%3c/svg%3e"); }

.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e"); }

.bp3-control.bp3-radio .bp3-control-indicator {
  border-radius: 50%; }

.bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
  background-image: radial-gradient(#ffffff, #ffffff 28%, transparent 32%); }

.bp3-control.bp3-radio input:checked:disabled ~ .bp3-control-indicator::before {
  opacity: 0.5; }

.bp3-control.bp3-radio input:focus ~ .bp3-control-indicator {
  -moz-outline-radius: 16px; }

.bp3-control.bp3-switch input ~ .bp3-control-indicator {
  background: rgba(167, 182, 194, 0.5); }

.bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
  background: rgba(115, 134, 148, 0.5); }

.bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
  background: rgba(92, 112, 128, 0.5); }

.bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
  background: rgba(206, 217, 224, 0.5); }

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
  background: #137cbd; }

.bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
  background: #106ba3; }

.bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
  background: #0e5a8a; }

.bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
  background: rgba(19, 124, 189, 0.5); }

.bp3-control.bp3-switch:not(.bp3-align-right) {
  padding-left: 38px; }

.bp3-control.bp3-switch:not(.bp3-align-right) .bp3-control-indicator {
  margin-left: -38px; }

.bp3-control.bp3-switch.bp3-align-right {
  padding-right: 38px; }

.bp3-control.bp3-switch.bp3-align-right .bp3-control-indicator {
  margin-right: -38px; }

.bp3-control.bp3-switch .bp3-control-indicator {
  border: none;
  border-radius: 1.75em;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: auto;
  min-width: 1.75em;
  -webkit-transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-control.bp3-switch .bp3-control-indicator::before {
  position: absolute;
  left: 0;
  margin: 2px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  -webkit-transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
  left: calc(100% - 1em); }

.bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) {
  padding-left: 45px; }

.bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
  margin-left: -45px; }

.bp3-control.bp3-switch.bp3-large.bp3-align-right {
  padding-right: 45px; }

.bp3-control.bp3-switch.bp3-large.bp3-align-right .bp3-control-indicator {
  margin-right: -45px; }

.bp3-dark .bp3-control.bp3-switch input ~ .bp3-control-indicator {
  background: rgba(16, 22, 26, 0.5); }

.bp3-dark .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
  background: rgba(16, 22, 26, 0.7); }

.bp3-dark .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
  background: rgba(16, 22, 26, 0.9); }

.bp3-dark .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
  background: rgba(57, 75, 89, 0.5); }

.bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
  background: #137cbd; }

.bp3-dark .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
  background: #106ba3; }

.bp3-dark .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
  background: #0e5a8a; }

.bp3-dark .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
  background: rgba(14, 90, 138, 0.5); }

.bp3-dark .bp3-control.bp3-switch .bp3-control-indicator::before {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background: #394b59; }

.bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-control.bp3-switch .bp3-switch-inner-text {
  text-align: center;
  font-size: 0.7em; }

.bp3-control.bp3-switch .bp3-control-indicator-child:first-child {
  visibility: hidden;
  margin-right: 1.2em;
  margin-left: 0.5em;
  line-height: 0; }

.bp3-control.bp3-switch .bp3-control-indicator-child:last-child {
  visibility: visible;
  margin-right: 0.5em;
  margin-left: 1.2em;
  line-height: 1em; }

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:first-child {
  visibility: visible;
  line-height: 1em; }

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:last-child {
  visibility: hidden;
  line-height: 0; }

.bp3-dark .bp3-control {
  color: #f5f8fa; }

.bp3-dark .bp3-control.bp3-disabled {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-control .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.05)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0)); }

.bp3-dark .bp3-control:hover .bp3-control-indicator {
  background-color: #30404d; }

.bp3-dark .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background: #202b33; }

.bp3-dark .bp3-control input:disabled ~ .bp3-control-indicator {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(57, 75, 89, 0.5);
  cursor: not-allowed; }

.bp3-dark .bp3-control.bp3-checkbox input:disabled:checked ~ .bp3-control-indicator, .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
  color: rgba(167, 182, 194, 0.6); }

.bp3-file-input {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 30px; }

.bp3-file-input input {
  opacity: 0;
  margin: 0;
  min-width: 200px; }

.bp3-file-input input:disabled + .bp3-file-upload-input,
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6);
  resize: none; }

.bp3-file-input input:disabled + .bp3-file-upload-input::after,
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  background-image: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active:hover,
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active,
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active:hover {
  background: rgba(206, 217, 224, 0.7); }

.bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input, .bp3-dark
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(57, 75, 89, 0.5);
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after, .bp3-dark
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(57, 75, 89, 0.5);
  background-image: none;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-dark
.bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active {
  background: rgba(57, 75, 89, 0.7); }

.bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
  color: #182026; }

.bp3-dark .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
  color: #f5f8fa; }

.bp3-file-input.bp3-fill {
  width: 100%; }

.bp3-file-input.bp3-large,
.bp3-large .bp3-file-input {
  height: 40px; }

.bp3-file-upload-input {
  outline: none;
  border: none;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-right: 80px;
  color: rgba(92, 112, 128, 0.6);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-file-upload-input::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-file-upload-input::-ms-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-file-upload-input::placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-file-upload-input:focus, .bp3-file-upload-input.bp3-active {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-file-upload-input[type="search"], .bp3-file-upload-input.bp3-round {
  border-radius: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px; }

.bp3-file-upload-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }

.bp3-file-upload-input:disabled, .bp3-file-upload-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6);
  resize: none; }

.bp3-file-upload-input::after {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  min-width: 24px;
  min-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3px;
  border-radius: 3px;
  width: 70px;
  text-align: center;
  line-height: 24px;
  content: "Browse"; }

.bp3-file-upload-input::after:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #ebf1f5; }

.bp3-file-upload-input::after:active, .bp3-file-upload-input::after.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #d8e1e8;
  background-image: none; }

.bp3-file-upload-input::after:disabled, .bp3-file-upload-input::after.bp3-disabled {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  background-image: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-file-upload-input::after:disabled.bp3-active, .bp3-file-upload-input::after:disabled.bp3-active:hover, .bp3-file-upload-input::after.bp3-disabled.bp3-active, .bp3-file-upload-input::after.bp3-disabled.bp3-active:hover {
  background: rgba(206, 217, 224, 0.7); }

.bp3-file-upload-input:hover::after {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #ebf1f5; }

.bp3-file-upload-input:active::after {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #d8e1e8;
  background-image: none; }

.bp3-large .bp3-file-upload-input {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-right: 95px; }

.bp3-large .bp3-file-upload-input[type="search"], .bp3-large .bp3-file-upload-input.bp3-round {
  padding: 0 15px; }

.bp3-large .bp3-file-upload-input::after {
  min-width: 30px;
  min-height: 30px;
  margin: 5px;
  width: 85px;
  line-height: 30px; }

.bp3-dark .bp3-file-upload-input {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #f5f8fa;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-upload-input::-webkit-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-upload-input::-ms-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-upload-input::placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-upload-input:focus {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-file-upload-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-file-upload-input:disabled, .bp3-dark .bp3-file-upload-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(57, 75, 89, 0.5);
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-upload-input::after {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.05)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }

.bp3-dark .bp3-file-upload-input::after:hover, .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
  color: #f5f8fa; }

.bp3-dark .bp3-file-upload-input::after:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #30404d; }

.bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #202b33;
  background-image: none; }

.bp3-dark .bp3-file-upload-input::after:disabled, .bp3-dark .bp3-file-upload-input::after.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(57, 75, 89, 0.5);
  background-image: none;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-file-upload-input::after:disabled.bp3-active, .bp3-dark .bp3-file-upload-input::after.bp3-disabled.bp3-active {
  background: rgba(57, 75, 89, 0.7); }

.bp3-dark .bp3-file-upload-input::after .bp3-button-spinner .bp3-spinner-head {
  background: rgba(16, 22, 26, 0.5);
  stroke: #8a9ba8; }

.bp3-dark .bp3-file-upload-input:hover::after {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #30404d; }

.bp3-dark .bp3-file-upload-input:active::after {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #202b33;
  background-image: none; }

.bp3-file-upload-input::after {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }

.bp3-form-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 0 15px; }

.bp3-form-group label.bp3-label {
  margin-bottom: 5px; }

.bp3-form-group .bp3-control {
  margin-top: 7px; }

.bp3-form-group .bp3-form-helper-text {
  margin-top: 5px;
  color: #5c7080;
  font-size: 12px; }

.bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
  color: #106ba3; }

.bp3-form-group.bp3-intent-success .bp3-form-helper-text {
  color: #0d8050; }

.bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
  color: #bf7326; }

.bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
  color: #c23030; }

.bp3-form-group.bp3-inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.bp3-form-group.bp3-inline.bp3-large label.bp3-label {
  margin: 0 10px 0 0;
  line-height: 40px; }

.bp3-form-group.bp3-inline label.bp3-label {
  margin: 0 10px 0 0;
  line-height: 30px; }

.bp3-form-group.bp3-disabled .bp3-label,
.bp3-form-group.bp3-disabled .bp3-text-muted,
.bp3-form-group.bp3-disabled .bp3-form-helper-text {
  color: rgba(92, 112, 128, 0.6) !important; }

.bp3-dark .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
  color: #48aff0; }

.bp3-dark .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
  color: #3dcc91; }

.bp3-dark .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
  color: #ffb366; }

.bp3-dark .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
  color: #ff7373; }

.bp3-dark .bp3-form-group .bp3-form-helper-text {
  color: #a7b6c2; }

.bp3-dark .bp3-form-group.bp3-disabled .bp3-label,
.bp3-dark .bp3-form-group.bp3-disabled .bp3-text-muted,
.bp3-dark .bp3-form-group.bp3-disabled .bp3-form-helper-text {
  color: rgba(167, 182, 194, 0.6) !important; }

.bp3-input-group {
  display: block;
  position: relative; }

.bp3-input-group .bp3-input {
  position: relative;
  width: 100%; }

.bp3-input-group .bp3-input:not(:first-child) {
  padding-left: 30px; }

.bp3-input-group .bp3-input:not(:last-child) {
  padding-right: 30px; }

.bp3-input-group .bp3-input-action,
.bp3-input-group > .bp3-button,
.bp3-input-group > .bp3-icon {
  position: absolute;
  top: 0; }

.bp3-input-group .bp3-input-action:first-child,
.bp3-input-group > .bp3-button:first-child,
.bp3-input-group > .bp3-icon:first-child {
  left: 0; }

.bp3-input-group .bp3-input-action:last-child,
.bp3-input-group > .bp3-button:last-child,
.bp3-input-group > .bp3-icon:last-child {
  right: 0; }

.bp3-input-group .bp3-button {
  min-width: 24px;
  min-height: 24px;
  margin: 3px;
  padding: 0 7px; }

.bp3-input-group .bp3-button:empty {
  padding: 0; }

.bp3-input-group > .bp3-icon {
  z-index: 1;
  color: #5c7080; }

.bp3-input-group > .bp3-icon:empty {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.bp3-input-group > .bp3-icon,
.bp3-input-group .bp3-input-action > .bp3-spinner {
  margin: 7px; }

.bp3-input-group .bp3-tag {
  margin: 5px; }

.bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus),
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
  color: #5c7080; }

.bp3-dark .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus), .bp3-dark
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
  color: #a7b6c2; }

.bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large {
  color: #5c7080; }

.bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled {
  color: rgba(92, 112, 128, 0.6) !important; }

.bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-large,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-standard,
.bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-large {
  color: rgba(92, 112, 128, 0.6) !important; }

.bp3-input-group.bp3-disabled {
  cursor: not-allowed; }

.bp3-input-group.bp3-disabled .bp3-icon {
  color: rgba(92, 112, 128, 0.6); }

.bp3-input-group.bp3-large .bp3-button {
  min-width: 30px;
  min-height: 30px;
  margin: 5px; }

.bp3-input-group.bp3-large > .bp3-icon,
.bp3-input-group.bp3-large .bp3-input-action > .bp3-spinner {
  margin: 12px; }

.bp3-input-group.bp3-large .bp3-input {
  height: 40px;
  line-height: 40px;
  font-size: 16px; }

.bp3-input-group.bp3-large .bp3-input[type="search"], .bp3-input-group.bp3-large .bp3-input.bp3-round {
  padding: 0 15px; }

.bp3-input-group.bp3-large .bp3-input:not(:first-child) {
  padding-left: 40px; }

.bp3-input-group.bp3-large .bp3-input:not(:last-child) {
  padding-right: 40px; }

.bp3-input-group.bp3-small .bp3-button {
  min-width: 20px;
  min-height: 20px;
  margin: 2px; }

.bp3-input-group.bp3-small .bp3-tag {
  min-width: 20px;
  min-height: 20px;
  margin: 2px; }

.bp3-input-group.bp3-small > .bp3-icon,
.bp3-input-group.bp3-small .bp3-input-action > .bp3-spinner {
  margin: 4px; }

.bp3-input-group.bp3-small .bp3-input {
  height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  line-height: 24px;
  font-size: 12px; }

.bp3-input-group.bp3-small .bp3-input[type="search"], .bp3-input-group.bp3-small .bp3-input.bp3-round {
  padding: 0 12px; }

.bp3-input-group.bp3-small .bp3-input:not(:first-child) {
  padding-left: 24px; }

.bp3-input-group.bp3-small .bp3-input:not(:last-child) {
  padding-right: 24px; }

.bp3-input-group.bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%; }

.bp3-input-group.bp3-round .bp3-button,
.bp3-input-group.bp3-round .bp3-input,
.bp3-input-group.bp3-round .bp3-tag {
  border-radius: 30px; }

.bp3-dark .bp3-input-group .bp3-icon {
  color: #a7b6c2; }

.bp3-dark .bp3-input-group.bp3-disabled .bp3-icon {
  color: rgba(167, 182, 194, 0.6); }

.bp3-input-group.bp3-intent-primary .bp3-input {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-primary .bp3-input:focus {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-primary .bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #137cbd;
  box-shadow: inset 0 0 0 1px #137cbd; }

.bp3-input-group.bp3-intent-primary .bp3-input:disabled, .bp3-input-group.bp3-intent-primary .bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input-group.bp3-intent-primary > .bp3-icon {
  color: #106ba3; }

.bp3-dark .bp3-input-group.bp3-intent-primary > .bp3-icon {
  color: #48aff0; }

.bp3-input-group.bp3-intent-success .bp3-input {
  -webkit-box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-success .bp3-input:focus {
  -webkit-box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-success .bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #0f9960;
  box-shadow: inset 0 0 0 1px #0f9960; }

.bp3-input-group.bp3-intent-success .bp3-input:disabled, .bp3-input-group.bp3-intent-success .bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input-group.bp3-intent-success > .bp3-icon {
  color: #0d8050; }

.bp3-dark .bp3-input-group.bp3-intent-success > .bp3-icon {
  color: #3dcc91; }

.bp3-input-group.bp3-intent-warning .bp3-input {
  -webkit-box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-warning .bp3-input:focus {
  -webkit-box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-warning .bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #d9822b;
  box-shadow: inset 0 0 0 1px #d9822b; }

.bp3-input-group.bp3-intent-warning .bp3-input:disabled, .bp3-input-group.bp3-intent-warning .bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input-group.bp3-intent-warning > .bp3-icon {
  color: #bf7326; }

.bp3-dark .bp3-input-group.bp3-intent-warning > .bp3-icon {
  color: #ffb366; }

.bp3-input-group.bp3-intent-danger .bp3-input {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-danger .bp3-input:focus {
  -webkit-box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input-group.bp3-intent-danger .bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #db3737;
  box-shadow: inset 0 0 0 1px #db3737; }

.bp3-input-group.bp3-intent-danger .bp3-input:disabled, .bp3-input-group.bp3-intent-danger .bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input-group.bp3-intent-danger > .bp3-icon {
  color: #c23030; }

.bp3-dark .bp3-input-group.bp3-intent-danger > .bp3-icon {
  color: #ff7373; }

.bp3-input {
  outline: none;
  border: none;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.bp3-input::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-input::-ms-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-input::placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-input:focus, .bp3-input.bp3-active {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input[type="search"], .bp3-input.bp3-round {
  border-radius: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px; }

.bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }

.bp3-input:disabled, .bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6);
  resize: none; }

.bp3-input.bp3-large {
  height: 40px;
  line-height: 40px;
  font-size: 16px; }

.bp3-input.bp3-large[type="search"], .bp3-input.bp3-large.bp3-round {
  padding: 0 15px; }

.bp3-input.bp3-small {
  height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  line-height: 24px;
  font-size: 12px; }

.bp3-input.bp3-small[type="search"], .bp3-input.bp3-small.bp3-round {
  padding: 0 12px; }

.bp3-input.bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%; }

.bp3-dark .bp3-input {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #f5f8fa; }

.bp3-dark .bp3-input::-webkit-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-input::-ms-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-input::placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-input:focus {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input:disabled, .bp3-dark .bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(57, 75, 89, 0.5);
  color: rgba(167, 182, 194, 0.6); }

.bp3-input.bp3-intent-primary {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-primary:focus {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-primary[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #137cbd;
  box-shadow: inset 0 0 0 1px #137cbd; }

.bp3-input.bp3-intent-primary:disabled, .bp3-input.bp3-intent-primary.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark .bp3-input.bp3-intent-primary {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-primary:focus {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-primary[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #137cbd;
  box-shadow: inset 0 0 0 1px #137cbd; }

.bp3-dark .bp3-input.bp3-intent-primary:disabled, .bp3-dark .bp3-input.bp3-intent-primary.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input.bp3-intent-success {
  -webkit-box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-success:focus {
  -webkit-box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-success[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #0f9960;
  box-shadow: inset 0 0 0 1px #0f9960; }

.bp3-input.bp3-intent-success:disabled, .bp3-input.bp3-intent-success.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark .bp3-input.bp3-intent-success {
  -webkit-box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-success:focus {
  -webkit-box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-success[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #0f9960;
  box-shadow: inset 0 0 0 1px #0f9960; }

.bp3-dark .bp3-input.bp3-intent-success:disabled, .bp3-dark .bp3-input.bp3-intent-success.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input.bp3-intent-warning {
  -webkit-box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-warning:focus {
  -webkit-box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-warning[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #d9822b;
  box-shadow: inset 0 0 0 1px #d9822b; }

.bp3-input.bp3-intent-warning:disabled, .bp3-input.bp3-intent-warning.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark .bp3-input.bp3-intent-warning {
  -webkit-box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-warning:focus {
  -webkit-box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-warning[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #d9822b;
  box-shadow: inset 0 0 0 1px #d9822b; }

.bp3-dark .bp3-input.bp3-intent-warning:disabled, .bp3-dark .bp3-input.bp3-intent-warning.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input.bp3-intent-danger {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-danger:focus {
  -webkit-box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.bp3-input.bp3-intent-danger[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #db3737;
  box-shadow: inset 0 0 0 1px #db3737; }

.bp3-input.bp3-intent-danger:disabled, .bp3-input.bp3-intent-danger.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark .bp3-input.bp3-intent-danger {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-danger:focus {
  -webkit-box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input.bp3-intent-danger[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px #db3737;
  box-shadow: inset 0 0 0 1px #db3737; }

.bp3-dark .bp3-input.bp3-intent-danger:disabled, .bp3-dark .bp3-input.bp3-intent-danger.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-input::-ms-clear {
  display: none; }

textarea.bp3-input {
  max-width: 100%;
  padding: 10px; }

textarea.bp3-input, textarea.bp3-input.bp3-large, textarea.bp3-input.bp3-small {
  height: auto;
  line-height: inherit; }

textarea.bp3-input.bp3-small {
  padding: 8px; }

.bp3-dark textarea.bp3-input {
  -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #f5f8fa; }

.bp3-dark textarea.bp3-input::-webkit-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark textarea.bp3-input::-ms-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark textarea.bp3-input::placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark textarea.bp3-input:focus {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark textarea.bp3-input[readonly] {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark textarea.bp3-input:disabled, .bp3-dark textarea.bp3-input.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(57, 75, 89, 0.5);
  color: rgba(167, 182, 194, 0.6); }

label.bp3-label {
  display: block;
  margin-top: 0;
  margin-bottom: 15px; }

label.bp3-label .bp3-html-select,
label.bp3-label .bp3-input,
label.bp3-label .bp3-select,
label.bp3-label .bp3-slider,
label.bp3-label .bp3-popover-wrapper {
  display: block;
  margin-top: 5px;
  text-transform: none; }

label.bp3-label .bp3-select select,
label.bp3-label .bp3-html-select select {
  width: 100%;
  vertical-align: top;
  font-weight: 400; }

label.bp3-label.bp3-disabled,
label.bp3-label.bp3-disabled .bp3-text-muted {
  color: rgba(92, 112, 128, 0.6); }

label.bp3-label.bp3-inline {
  line-height: 30px; }

label.bp3-label.bp3-inline .bp3-html-select,
label.bp3-label.bp3-inline .bp3-input,
label.bp3-label.bp3-inline .bp3-input-group,
label.bp3-label.bp3-inline .bp3-select,
label.bp3-label.bp3-inline .bp3-popover-wrapper {
  display: inline-block;
  margin: 0 0 0 5px;
  vertical-align: top; }

label.bp3-label.bp3-inline .bp3-input-group .bp3-input {
  margin-left: 0; }

label.bp3-label.bp3-inline.bp3-large {
  line-height: 40px; }

label.bp3-label:not(.bp3-inline) .bp3-popover-target {
  display: block; }

.bp3-dark label.bp3-label {
  color: #f5f8fa; }

.bp3-dark label.bp3-label.bp3-disabled,
.bp3-dark label.bp3-label.bp3-disabled .bp3-text-muted {
  color: rgba(167, 182, 194, 0.6); }

.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 14px;
  -ms-flex: 1 1 14px;
  flex: 1 1 14px;
  width: 30px;
  min-height: 0;
  padding: 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:first-child {
  border-radius: 0 3px 0 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:last-child {
  border-radius: 0 0 3px 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:first-child {
  border-radius: 3px 0 0 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:last-child {
  border-radius: 0 0 0 3px; }

.bp3-numeric-input.bp3-large .bp3-button-group.bp3-vertical > .bp3-button {
  width: 40px; }

form {
  display: block; }

.bp3-html-select select,
.bp3-select select {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  padding: 0 25px 0 10px;
  -moz-appearance: none;
  -webkit-appearance: none; }

.bp3-html-select select > *, .bp3-select select > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-html-select select > .bp3-fill, .bp3-select select > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-html-select select::before,
.bp3-select select::before, .bp3-html-select select > *, .bp3-select select > * {
  margin-right: 7px; }

.bp3-html-select select:empty::before,
.bp3-select select:empty::before,
.bp3-html-select select > :last-child,
.bp3-select select > :last-child {
  margin-right: 0; }

.bp3-html-select select:hover,
.bp3-select select:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #ebf1f5; }

.bp3-html-select select:active,
.bp3-select select:active, .bp3-html-select select.bp3-active,
.bp3-select select.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #d8e1e8;
  background-image: none; }

.bp3-html-select select:disabled,
.bp3-select select:disabled, .bp3-html-select select.bp3-disabled,
.bp3-select select.bp3-disabled {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  background-image: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-html-select select:disabled.bp3-active,
.bp3-select select:disabled.bp3-active, .bp3-html-select select:disabled.bp3-active:hover,
.bp3-select select:disabled.bp3-active:hover, .bp3-html-select select.bp3-disabled.bp3-active,
.bp3-select select.bp3-disabled.bp3-active, .bp3-html-select select.bp3-disabled.bp3-active:hover,
.bp3-select select.bp3-disabled.bp3-active:hover {
  background: rgba(206, 217, 224, 0.7); }

.bp3-html-select.bp3-minimal select,
.bp3-select.bp3-minimal select {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-html-select.bp3-minimal select:hover,
.bp3-select.bp3-minimal select:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(167, 182, 194, 0.3);
  text-decoration: none;
  color: #182026; }

.bp3-html-select.bp3-minimal select:active,
.bp3-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal select.bp3-active,
.bp3-select.bp3-minimal select.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(115, 134, 148, 0.3);
  color: #182026; }

.bp3-html-select.bp3-minimal select:disabled,
.bp3-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal select:disabled:hover,
.bp3-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal select.bp3-disabled,
.bp3-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal select.bp3-disabled:hover,
.bp3-select.bp3-minimal select.bp3-disabled:hover {
  background: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-html-select.bp3-minimal select:disabled.bp3-active,
.bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active,
.bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active,
.bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active {
  background: rgba(115, 134, 148, 0.3); }

.bp3-dark .bp3-html-select.bp3-minimal select, .bp3-html-select.bp3-minimal .bp3-dark select,
.bp3-dark .bp3-select.bp3-minimal select, .bp3-select.bp3-minimal .bp3-dark select {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: inherit; }

.bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
.bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover, .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
.bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
.bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover {
  background: rgba(138, 155, 168, 0.15); }

.bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
.bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
  background: rgba(138, 155, 168, 0.3);
  color: #f5f8fa; }

.bp3-dark .bp3-html-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal .bp3-dark select:disabled,
.bp3-dark .bp3-select.bp3-minimal select:disabled, .bp3-select.bp3-minimal .bp3-dark select:disabled, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover,
.bp3-dark .bp3-select.bp3-minimal select:disabled:hover, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover,
.bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover {
  background: none;
  cursor: not-allowed;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-html-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active {
  background: rgba(138, 155, 168, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-primary,
.bp3-select.bp3-minimal select.bp3-intent-primary {
  color: #106ba3; }

.bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
.bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
.bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #106ba3; }

.bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
.bp3-select.bp3-minimal select.bp3-intent-primary:hover {
  background: rgba(19, 124, 189, 0.15);
  color: #106ba3; }

.bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
.bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
  background: rgba(19, 124, 189, 0.3);
  color: #106ba3; }

.bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled,
.bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled,
.bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled {
  background: none;
  color: rgba(16, 107, 163, 0.5); }

.bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active {
  background: rgba(19, 124, 189, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
  stroke: #106ba3; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary {
  color: #48aff0; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover {
  background: rgba(19, 124, 189, 0.2);
  color: #48aff0; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active {
  background: rgba(19, 124, 189, 0.3);
  color: #48aff0; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled {
  background: none;
  color: rgba(72, 175, 240, 0.5); }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active {
  background: rgba(19, 124, 189, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-success,
.bp3-select.bp3-minimal select.bp3-intent-success {
  color: #0d8050; }

.bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
.bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
.bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #0d8050; }

.bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
.bp3-select.bp3-minimal select.bp3-intent-success:hover {
  background: rgba(15, 153, 96, 0.15);
  color: #0d8050; }

.bp3-html-select.bp3-minimal select.bp3-intent-success:active,
.bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
  background: rgba(15, 153, 96, 0.3);
  color: #0d8050; }

.bp3-html-select.bp3-minimal select.bp3-intent-success:disabled,
.bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled,
.bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled {
  background: none;
  color: rgba(13, 128, 80, 0.5); }

.bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active {
  background: rgba(15, 153, 96, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
  stroke: #0d8050; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success {
  color: #3dcc91; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover {
  background: rgba(15, 153, 96, 0.2);
  color: #3dcc91; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active {
  background: rgba(15, 153, 96, 0.3);
  color: #3dcc91; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled {
  background: none;
  color: rgba(61, 204, 145, 0.5); }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active {
  background: rgba(15, 153, 96, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-warning,
.bp3-select.bp3-minimal select.bp3-intent-warning {
  color: #bf7326; }

.bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
.bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
.bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #bf7326; }

.bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
.bp3-select.bp3-minimal select.bp3-intent-warning:hover {
  background: rgba(217, 130, 43, 0.15);
  color: #bf7326; }

.bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
.bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
  background: rgba(217, 130, 43, 0.3);
  color: #bf7326; }

.bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled,
.bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled,
.bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled {
  background: none;
  color: rgba(191, 115, 38, 0.5); }

.bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active {
  background: rgba(217, 130, 43, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
  stroke: #bf7326; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning {
  color: #ffb366; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover {
  background: rgba(217, 130, 43, 0.2);
  color: #ffb366; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active {
  background: rgba(217, 130, 43, 0.3);
  color: #ffb366; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled {
  background: none;
  color: rgba(255, 179, 102, 0.5); }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active {
  background: rgba(217, 130, 43, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-danger,
.bp3-select.bp3-minimal select.bp3-intent-danger {
  color: #c23030; }

.bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
.bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
.bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  color: #c23030; }

.bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
.bp3-select.bp3-minimal select.bp3-intent-danger:hover {
  background: rgba(219, 55, 55, 0.15);
  color: #c23030; }

.bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
.bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
  background: rgba(219, 55, 55, 0.3);
  color: #c23030; }

.bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled,
.bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled,
.bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled {
  background: none;
  color: rgba(194, 48, 48, 0.5); }

.bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active,
.bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active {
  background: rgba(219, 55, 55, 0.3); }

.bp3-html-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
  stroke: #c23030; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger {
  color: #ff7373; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover {
  background: rgba(219, 55, 55, 0.2);
  color: #ff7373; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active {
  background: rgba(219, 55, 55, 0.3);
  color: #ff7373; }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled {
  background: none;
  color: rgba(255, 115, 115, 0.5); }

.bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active,
.bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active {
  background: rgba(219, 55, 55, 0.3); }

.bp3-html-select.bp3-large select,
.bp3-select.bp3-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.bp3-dark .bp3-html-select select, .bp3-dark .bp3-select select {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.05)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }

.bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover, .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
  color: #f5f8fa; }

.bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #30404d; }

.bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #202b33;
  background-image: none; }

.bp3-dark .bp3-html-select select:disabled, .bp3-dark .bp3-select select:disabled, .bp3-dark .bp3-html-select select.bp3-disabled, .bp3-dark .bp3-select select.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(57, 75, 89, 0.5);
  background-image: none;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-html-select select:disabled.bp3-active, .bp3-dark .bp3-select select:disabled.bp3-active, .bp3-dark .bp3-html-select select.bp3-disabled.bp3-active, .bp3-dark .bp3-select select.bp3-disabled.bp3-active {
  background: rgba(57, 75, 89, 0.7); }

.bp3-dark .bp3-html-select select .bp3-button-spinner .bp3-spinner-head, .bp3-dark .bp3-select select .bp3-button-spinner .bp3-spinner-head {
  background: rgba(16, 22, 26, 0.5);
  stroke: #8a9ba8; }

.bp3-html-select select:disabled,
.bp3-select select:disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-html-select .bp3-icon,
.bp3-select .bp3-icon, .bp3-select::after {
  position: absolute;
  top: 7px;
  right: 7px;
  color: #5c7080;
  pointer-events: none; }

.bp3-html-select .bp3-disabled.bp3-icon,
.bp3-select .bp3-disabled.bp3-icon, .bp3-disabled.bp3-select::after {
  color: rgba(92, 112, 128, 0.6); }

.bp3-html-select,
.bp3-select {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: normal; }

.bp3-html-select select::-ms-expand,
.bp3-select select::-ms-expand {
  display: none; }

.bp3-html-select .bp3-icon,
.bp3-select .bp3-icon {
  color: #5c7080; }

.bp3-html-select .bp3-icon:hover,
.bp3-select .bp3-icon:hover {
  color: #182026; }

.bp3-dark .bp3-html-select .bp3-icon, .bp3-dark
.bp3-select .bp3-icon {
  color: #a7b6c2; }

.bp3-dark .bp3-html-select .bp3-icon:hover, .bp3-dark
.bp3-select .bp3-icon:hover {
  color: #f5f8fa; }

.bp3-html-select.bp3-large::after,
.bp3-html-select.bp3-large .bp3-icon,
.bp3-select.bp3-large::after,
.bp3-select.bp3-large .bp3-icon {
  top: 12px;
  right: 12px; }

.bp3-html-select.bp3-fill,
.bp3-html-select.bp3-fill select,
.bp3-select.bp3-fill,
.bp3-select.bp3-fill select {
  width: 100%; }

.bp3-dark .bp3-html-select option, .bp3-dark
.bp3-select option {
  background-color: #30404d;
  color: #f5f8fa; }

.bp3-dark .bp3-html-select::after, .bp3-dark
.bp3-select::after {
  color: #a7b6c2; }

.bp3-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: ""; }

.bp3-running-text table, table.bp3-html-table {
  border-spacing: 0;
  font-size: 14px; }

.bp3-running-text table th, table.bp3-html-table th,
.bp3-running-text table td,
table.bp3-html-table td {
  padding: 11px;
  vertical-align: top;
  text-align: left; }

.bp3-running-text table th, table.bp3-html-table th {
  color: #182026;
  font-weight: 600; }

.bp3-running-text table td,
table.bp3-html-table td {
  color: #182026; }

.bp3-running-text table tbody tr:first-child th, table.bp3-html-table tbody tr:first-child th,
.bp3-running-text table tbody tr:first-child td,
table.bp3-html-table tbody tr:first-child td {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-dark .bp3-running-text table th, .bp3-running-text .bp3-dark table th, .bp3-dark table.bp3-html-table th {
  color: #f5f8fa; }

.bp3-dark .bp3-running-text table td, .bp3-running-text .bp3-dark table td, .bp3-dark table.bp3-html-table td {
  color: #f5f8fa; }

.bp3-dark .bp3-running-text table tbody tr:first-child th, .bp3-running-text .bp3-dark table tbody tr:first-child th, .bp3-dark table.bp3-html-table tbody tr:first-child th,
.bp3-dark .bp3-running-text table tbody tr:first-child td,
.bp3-running-text .bp3-dark table tbody tr:first-child td,
.bp3-dark table.bp3-html-table tbody tr:first-child td {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }

table.bp3-html-table.bp3-html-table-condensed th,
table.bp3-html-table.bp3-html-table-condensed td, table.bp3-html-table.bp3-small th,
table.bp3-html-table.bp3-small td {
  padding-top: 6px;
  padding-bottom: 6px; }

table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(191, 204, 214, 0.15); }

table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  -webkit-box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child) {
  -webkit-box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  -webkit-box-shadow: none;
  box-shadow: none; }

table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
  -webkit-box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(191, 204, 214, 0.3);
  cursor: pointer; }

table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(191, 204, 214, 0.4); }

.bp3-dark table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(92, 112, 128, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  -webkit-box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child) {
  -webkit-box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  -webkit-box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:first-child {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(92, 112, 128, 0.3);
  cursor: pointer; }

.bp3-dark table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(92, 112, 128, 0.4); }

.bp3-key-combo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.bp3-key-combo > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-key-combo > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-key-combo::before,
.bp3-key-combo > * {
  margin-right: 5px; }

.bp3-key-combo:empty::before,
.bp3-key-combo > :last-child {
  margin-right: 0; }

.bp3-hotkey-dialog {
  top: 40px;
  padding-bottom: 0; }

.bp3-hotkey-dialog .bp3-dialog-body {
  margin: 0;
  padding: 0; }

.bp3-hotkey-dialog .bp3-hotkey-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.bp3-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px; }

.bp3-hotkey-column .bp3-heading {
  margin-bottom: 20px; }

.bp3-hotkey-column .bp3-heading:not(:first-child) {
  margin-top: 40px; }

.bp3-hotkey {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0; }

.bp3-hotkey:not(:last-child) {
  margin-bottom: 10px; }

.bp3-icon {
  display: inline-block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  vertical-align: text-bottom; }

.bp3-icon:not(:empty)::before {
  content: "" !important;
  content: unset !important; }

.bp3-icon > svg {
  display: block; }

.bp3-icon > svg:not([fill]) {
  fill: currentColor; }

.bp3-icon.bp3-intent-primary, .bp3-icon-standard.bp3-intent-primary, .bp3-icon-large.bp3-intent-primary {
  color: #106ba3; }

.bp3-dark .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-icon-large.bp3-intent-primary {
  color: #48aff0; }

.bp3-icon.bp3-intent-success, .bp3-icon-standard.bp3-intent-success, .bp3-icon-large.bp3-intent-success {
  color: #0d8050; }

.bp3-dark .bp3-icon.bp3-intent-success, .bp3-dark .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-icon-large.bp3-intent-success {
  color: #3dcc91; }

.bp3-icon.bp3-intent-warning, .bp3-icon-standard.bp3-intent-warning, .bp3-icon-large.bp3-intent-warning {
  color: #bf7326; }

.bp3-dark .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-icon-large.bp3-intent-warning {
  color: #ffb366; }

.bp3-icon.bp3-intent-danger, .bp3-icon-standard.bp3-intent-danger, .bp3-icon-large.bp3-intent-danger {
  color: #c23030; }

.bp3-dark .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-icon-large.bp3-intent-danger {
  color: #ff7373; }

span.bp3-icon-standard {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon-large {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon:empty {
  line-height: 1;
  font-family: "Icons20";
  font-size: inherit;
  font-weight: 400;
  font-style: normal; }

span.bp3-icon:empty::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.bp3-icon-add::before {
  content: ""; }

.bp3-icon-add-column-left::before {
  content: ""; }

.bp3-icon-add-column-right::before {
  content: ""; }

.bp3-icon-add-row-bottom::before {
  content: ""; }

.bp3-icon-add-row-top::before {
  content: ""; }

.bp3-icon-add-to-artifact::before {
  content: ""; }

.bp3-icon-add-to-folder::before {
  content: ""; }

.bp3-icon-airplane::before {
  content: ""; }

.bp3-icon-align-center::before {
  content: ""; }

.bp3-icon-align-justify::before {
  content: ""; }

.bp3-icon-align-left::before {
  content: ""; }

.bp3-icon-align-right::before {
  content: ""; }

.bp3-icon-alignment-bottom::before {
  content: ""; }

.bp3-icon-alignment-horizontal-center::before {
  content: ""; }

.bp3-icon-alignment-left::before {
  content: ""; }

.bp3-icon-alignment-right::before {
  content: ""; }

.bp3-icon-alignment-top::before {
  content: ""; }

.bp3-icon-alignment-vertical-center::before {
  content: ""; }

.bp3-icon-annotation::before {
  content: ""; }

.bp3-icon-application::before {
  content: ""; }

.bp3-icon-applications::before {
  content: ""; }

.bp3-icon-arrow-bottom-left::before {
  content: "↙"; }

.bp3-icon-arrow-bottom-right::before {
  content: "↘"; }

.bp3-icon-arrow-down::before {
  content: "↓"; }

.bp3-icon-arrow-left::before {
  content: "←"; }

.bp3-icon-arrow-right::before {
  content: "→"; }

.bp3-icon-arrow-top-left::before {
  content: "↖"; }

.bp3-icon-arrow-top-right::before {
  content: "↗"; }

.bp3-icon-arrow-up::before {
  content: "↑"; }

.bp3-icon-arrows-horizontal::before {
  content: "↔"; }

.bp3-icon-arrows-vertical::before {
  content: "↕"; }

.bp3-icon-asterisk::before {
  content: "*"; }

.bp3-icon-automatic-updates::before {
  content: ""; }

.bp3-icon-badge::before {
  content: ""; }

.bp3-icon-ban-circle::before {
  content: ""; }

.bp3-icon-bank-account::before {
  content: ""; }

.bp3-icon-barcode::before {
  content: ""; }

.bp3-icon-blank::before {
  content: ""; }

.bp3-icon-blocked-person::before {
  content: ""; }

.bp3-icon-bold::before {
  content: ""; }

.bp3-icon-book::before {
  content: ""; }

.bp3-icon-bookmark::before {
  content: ""; }

.bp3-icon-box::before {
  content: ""; }

.bp3-icon-briefcase::before {
  content: ""; }

.bp3-icon-build::before {
  content: ""; }

.bp3-icon-calculator::before {
  content: ""; }

.bp3-icon-calendar::before {
  content: ""; }

.bp3-icon-camera::before {
  content: ""; }

.bp3-icon-caret-down::before {
  content: "⌄"; }

.bp3-icon-caret-left::before {
  content: "〈"; }

.bp3-icon-caret-right::before {
  content: "〉"; }

.bp3-icon-caret-up::before {
  content: "⌃"; }

.bp3-icon-cell-tower::before {
  content: ""; }

.bp3-icon-changes::before {
  content: ""; }

.bp3-icon-chart::before {
  content: ""; }

.bp3-icon-chat::before {
  content: ""; }

.bp3-icon-chevron-backward::before {
  content: ""; }

.bp3-icon-chevron-down::before {
  content: ""; }

.bp3-icon-chevron-forward::before {
  content: ""; }

.bp3-icon-chevron-left::before {
  content: ""; }

.bp3-icon-chevron-right::before {
  content: ""; }

.bp3-icon-chevron-up::before {
  content: ""; }

.bp3-icon-circle::before {
  content: ""; }

.bp3-icon-circle-arrow-down::before {
  content: ""; }

.bp3-icon-circle-arrow-left::before {
  content: ""; }

.bp3-icon-circle-arrow-right::before {
  content: ""; }

.bp3-icon-circle-arrow-up::before {
  content: ""; }

.bp3-icon-citation::before {
  content: ""; }

.bp3-icon-clean::before {
  content: ""; }

.bp3-icon-clipboard::before {
  content: ""; }

.bp3-icon-cloud::before {
  content: "☁"; }

.bp3-icon-cloud-download::before {
  content: ""; }

.bp3-icon-cloud-upload::before {
  content: ""; }

.bp3-icon-code::before {
  content: ""; }

.bp3-icon-code-block::before {
  content: ""; }

.bp3-icon-cog::before {
  content: ""; }

.bp3-icon-collapse-all::before {
  content: ""; }

.bp3-icon-column-layout::before {
  content: ""; }

.bp3-icon-comment::before {
  content: ""; }

.bp3-icon-comparison::before {
  content: ""; }

.bp3-icon-compass::before {
  content: ""; }

.bp3-icon-compressed::before {
  content: ""; }

.bp3-icon-confirm::before {
  content: ""; }

.bp3-icon-console::before {
  content: ""; }

.bp3-icon-contrast::before {
  content: ""; }

.bp3-icon-control::before {
  content: ""; }

.bp3-icon-credit-card::before {
  content: ""; }

.bp3-icon-cross::before {
  content: "✗"; }

.bp3-icon-crown::before {
  content: ""; }

.bp3-icon-cube::before {
  content: ""; }

.bp3-icon-cube-add::before {
  content: ""; }

.bp3-icon-cube-remove::before {
  content: ""; }

.bp3-icon-curved-range-chart::before {
  content: ""; }

.bp3-icon-cut::before {
  content: ""; }

.bp3-icon-dashboard::before {
  content: ""; }

.bp3-icon-database::before {
  content: ""; }

.bp3-icon-delete::before {
  content: ""; }

.bp3-icon-delta::before {
  content: "Δ"; }

.bp3-icon-derive-column::before {
  content: ""; }

.bp3-icon-desktop::before {
  content: ""; }

.bp3-icon-diagram-tree::before {
  content: ""; }

.bp3-icon-direction-left::before {
  content: ""; }

.bp3-icon-direction-right::before {
  content: ""; }

.bp3-icon-disable::before {
  content: ""; }

.bp3-icon-document::before {
  content: ""; }

.bp3-icon-document-open::before {
  content: ""; }

.bp3-icon-document-share::before {
  content: ""; }

.bp3-icon-dollar::before {
  content: "$"; }

.bp3-icon-dot::before {
  content: "•"; }

.bp3-icon-double-caret-horizontal::before {
  content: ""; }

.bp3-icon-double-caret-vertical::before {
  content: ""; }

.bp3-icon-double-chevron-down::before {
  content: ""; }

.bp3-icon-double-chevron-left::before {
  content: ""; }

.bp3-icon-double-chevron-right::before {
  content: ""; }

.bp3-icon-double-chevron-up::before {
  content: ""; }

.bp3-icon-doughnut-chart::before {
  content: ""; }

.bp3-icon-download::before {
  content: ""; }

.bp3-icon-drag-handle-horizontal::before {
  content: ""; }

.bp3-icon-drag-handle-vertical::before {
  content: ""; }

.bp3-icon-draw::before {
  content: ""; }

.bp3-icon-drive-time::before {
  content: ""; }

.bp3-icon-duplicate::before {
  content: ""; }

.bp3-icon-edit::before {
  content: "✎"; }

.bp3-icon-eject::before {
  content: "⏏"; }

.bp3-icon-endorsed::before {
  content: ""; }

.bp3-icon-envelope::before {
  content: "✉"; }

.bp3-icon-equals::before {
  content: ""; }

.bp3-icon-eraser::before {
  content: ""; }

.bp3-icon-error::before {
  content: ""; }

.bp3-icon-euro::before {
  content: "€"; }

.bp3-icon-exchange::before {
  content: ""; }

.bp3-icon-exclude-row::before {
  content: ""; }

.bp3-icon-expand-all::before {
  content: ""; }

.bp3-icon-export::before {
  content: ""; }

.bp3-icon-eye-off::before {
  content: ""; }

.bp3-icon-eye-on::before {
  content: ""; }

.bp3-icon-eye-open::before {
  content: ""; }

.bp3-icon-fast-backward::before {
  content: ""; }

.bp3-icon-fast-forward::before {
  content: ""; }

.bp3-icon-feed::before {
  content: ""; }

.bp3-icon-feed-subscribed::before {
  content: ""; }

.bp3-icon-film::before {
  content: ""; }

.bp3-icon-filter::before {
  content: ""; }

.bp3-icon-filter-keep::before {
  content: ""; }

.bp3-icon-filter-list::before {
  content: ""; }

.bp3-icon-filter-open::before {
  content: ""; }

.bp3-icon-filter-remove::before {
  content: ""; }

.bp3-icon-flag::before {
  content: "⚑"; }

.bp3-icon-flame::before {
  content: ""; }

.bp3-icon-flash::before {
  content: ""; }

.bp3-icon-floppy-disk::before {
  content: ""; }

.bp3-icon-flow-branch::before {
  content: ""; }

.bp3-icon-flow-end::before {
  content: ""; }

.bp3-icon-flow-linear::before {
  content: ""; }

.bp3-icon-flow-review::before {
  content: ""; }

.bp3-icon-flow-review-branch::before {
  content: ""; }

.bp3-icon-flows::before {
  content: ""; }

.bp3-icon-folder-close::before {
  content: ""; }

.bp3-icon-folder-new::before {
  content: ""; }

.bp3-icon-folder-open::before {
  content: ""; }

.bp3-icon-folder-shared::before {
  content: ""; }

.bp3-icon-folder-shared-open::before {
  content: ""; }

.bp3-icon-follower::before {
  content: ""; }

.bp3-icon-following::before {
  content: ""; }

.bp3-icon-font::before {
  content: ""; }

.bp3-icon-fork::before {
  content: ""; }

.bp3-icon-form::before {
  content: ""; }

.bp3-icon-full-circle::before {
  content: ""; }

.bp3-icon-full-stacked-chart::before {
  content: ""; }

.bp3-icon-fullscreen::before {
  content: ""; }

.bp3-icon-function::before {
  content: ""; }

.bp3-icon-gantt-chart::before {
  content: ""; }

.bp3-icon-geolocation::before {
  content: ""; }

.bp3-icon-geosearch::before {
  content: ""; }

.bp3-icon-git-branch::before {
  content: ""; }

.bp3-icon-git-commit::before {
  content: ""; }

.bp3-icon-git-merge::before {
  content: ""; }

.bp3-icon-git-new-branch::before {
  content: ""; }

.bp3-icon-git-pull::before {
  content: ""; }

.bp3-icon-git-push::before {
  content: ""; }

.bp3-icon-git-repo::before {
  content: ""; }

.bp3-icon-glass::before {
  content: ""; }

.bp3-icon-globe::before {
  content: ""; }

.bp3-icon-globe-network::before {
  content: ""; }

.bp3-icon-graph::before {
  content: ""; }

.bp3-icon-graph-remove::before {
  content: ""; }

.bp3-icon-greater-than::before {
  content: ""; }

.bp3-icon-greater-than-or-equal-to::before {
  content: ""; }

.bp3-icon-grid::before {
  content: ""; }

.bp3-icon-grid-view::before {
  content: ""; }

.bp3-icon-group-objects::before {
  content: ""; }

.bp3-icon-grouped-bar-chart::before {
  content: ""; }

.bp3-icon-hand::before {
  content: ""; }

.bp3-icon-hand-down::before {
  content: ""; }

.bp3-icon-hand-left::before {
  content: ""; }

.bp3-icon-hand-right::before {
  content: ""; }

.bp3-icon-hand-up::before {
  content: ""; }

.bp3-icon-header::before {
  content: ""; }

.bp3-icon-header-one::before {
  content: ""; }

.bp3-icon-header-two::before {
  content: ""; }

.bp3-icon-headset::before {
  content: ""; }

.bp3-icon-heart::before {
  content: "♥"; }

.bp3-icon-heart-broken::before {
  content: ""; }

.bp3-icon-heat-grid::before {
  content: ""; }

.bp3-icon-heatmap::before {
  content: ""; }

.bp3-icon-help::before {
  content: "?"; }

.bp3-icon-helper-management::before {
  content: ""; }

.bp3-icon-highlight::before {
  content: ""; }

.bp3-icon-history::before {
  content: ""; }

.bp3-icon-home::before {
  content: "⌂"; }

.bp3-icon-horizontal-bar-chart::before {
  content: ""; }

.bp3-icon-horizontal-bar-chart-asc::before {
  content: ""; }

.bp3-icon-horizontal-bar-chart-desc::before {
  content: ""; }

.bp3-icon-horizontal-distribution::before {
  content: ""; }

.bp3-icon-id-number::before {
  content: ""; }

.bp3-icon-image-rotate-left::before {
  content: ""; }

.bp3-icon-image-rotate-right::before {
  content: ""; }

.bp3-icon-import::before {
  content: ""; }

.bp3-icon-inbox::before {
  content: ""; }

.bp3-icon-inbox-filtered::before {
  content: ""; }

.bp3-icon-inbox-geo::before {
  content: ""; }

.bp3-icon-inbox-search::before {
  content: ""; }

.bp3-icon-inbox-update::before {
  content: ""; }

.bp3-icon-info-sign::before {
  content: "ℹ"; }

.bp3-icon-inheritance::before {
  content: ""; }

.bp3-icon-inner-join::before {
  content: ""; }

.bp3-icon-insert::before {
  content: ""; }

.bp3-icon-intersection::before {
  content: ""; }

.bp3-icon-ip-address::before {
  content: ""; }

.bp3-icon-issue::before {
  content: ""; }

.bp3-icon-issue-closed::before {
  content: ""; }

.bp3-icon-issue-new::before {
  content: ""; }

.bp3-icon-italic::before {
  content: ""; }

.bp3-icon-join-table::before {
  content: ""; }

.bp3-icon-key::before {
  content: ""; }

.bp3-icon-key-backspace::before {
  content: ""; }

.bp3-icon-key-command::before {
  content: ""; }

.bp3-icon-key-control::before {
  content: ""; }

.bp3-icon-key-delete::before {
  content: ""; }

.bp3-icon-key-enter::before {
  content: ""; }

.bp3-icon-key-escape::before {
  content: ""; }

.bp3-icon-key-option::before {
  content: ""; }

.bp3-icon-key-shift::before {
  content: ""; }

.bp3-icon-key-tab::before {
  content: ""; }

.bp3-icon-known-vehicle::before {
  content: ""; }

.bp3-icon-label::before {
  content: ""; }

.bp3-icon-layer::before {
  content: ""; }

.bp3-icon-layers::before {
  content: ""; }

.bp3-icon-layout::before {
  content: ""; }

.bp3-icon-layout-auto::before {
  content: ""; }

.bp3-icon-layout-balloon::before {
  content: ""; }

.bp3-icon-layout-circle::before {
  content: ""; }

.bp3-icon-layout-grid::before {
  content: ""; }

.bp3-icon-layout-group-by::before {
  content: ""; }

.bp3-icon-layout-hierarchy::before {
  content: ""; }

.bp3-icon-layout-linear::before {
  content: ""; }

.bp3-icon-layout-skew-grid::before {
  content: ""; }

.bp3-icon-layout-sorted-clusters::before {
  content: ""; }

.bp3-icon-left-join::before {
  content: ""; }

.bp3-icon-less-than::before {
  content: ""; }

.bp3-icon-less-than-or-equal-to::before {
  content: ""; }

.bp3-icon-lifesaver::before {
  content: ""; }

.bp3-icon-lightbulb::before {
  content: ""; }

.bp3-icon-link::before {
  content: ""; }

.bp3-icon-list::before {
  content: "☰"; }

.bp3-icon-list-columns::before {
  content: ""; }

.bp3-icon-list-detail-view::before {
  content: ""; }

.bp3-icon-locate::before {
  content: ""; }

.bp3-icon-lock::before {
  content: ""; }

.bp3-icon-log-in::before {
  content: ""; }

.bp3-icon-log-out::before {
  content: ""; }

.bp3-icon-manual::before {
  content: ""; }

.bp3-icon-manually-entered-data::before {
  content: ""; }

.bp3-icon-map::before {
  content: ""; }

.bp3-icon-map-create::before {
  content: ""; }

.bp3-icon-map-marker::before {
  content: ""; }

.bp3-icon-maximize::before {
  content: ""; }

.bp3-icon-media::before {
  content: ""; }

.bp3-icon-menu::before {
  content: ""; }

.bp3-icon-menu-closed::before {
  content: ""; }

.bp3-icon-menu-open::before {
  content: ""; }

.bp3-icon-merge-columns::before {
  content: ""; }

.bp3-icon-merge-links::before {
  content: ""; }

.bp3-icon-minimize::before {
  content: ""; }

.bp3-icon-minus::before {
  content: "−"; }

.bp3-icon-mobile-phone::before {
  content: ""; }

.bp3-icon-mobile-video::before {
  content: ""; }

.bp3-icon-moon::before {
  content: ""; }

.bp3-icon-more::before {
  content: ""; }

.bp3-icon-mountain::before {
  content: ""; }

.bp3-icon-move::before {
  content: ""; }

.bp3-icon-mugshot::before {
  content: ""; }

.bp3-icon-multi-select::before {
  content: ""; }

.bp3-icon-music::before {
  content: ""; }

.bp3-icon-new-grid-item::before {
  content: ""; }

.bp3-icon-new-link::before {
  content: ""; }

.bp3-icon-new-object::before {
  content: ""; }

.bp3-icon-new-person::before {
  content: ""; }

.bp3-icon-new-prescription::before {
  content: ""; }

.bp3-icon-new-text-box::before {
  content: ""; }

.bp3-icon-ninja::before {
  content: ""; }

.bp3-icon-not-equal-to::before {
  content: ""; }

.bp3-icon-notifications::before {
  content: ""; }

.bp3-icon-notifications-updated::before {
  content: ""; }

.bp3-icon-numbered-list::before {
  content: ""; }

.bp3-icon-numerical::before {
  content: ""; }

.bp3-icon-office::before {
  content: ""; }

.bp3-icon-offline::before {
  content: ""; }

.bp3-icon-oil-field::before {
  content: ""; }

.bp3-icon-one-column::before {
  content: ""; }

.bp3-icon-outdated::before {
  content: ""; }

.bp3-icon-page-layout::before {
  content: ""; }

.bp3-icon-panel-stats::before {
  content: ""; }

.bp3-icon-panel-table::before {
  content: ""; }

.bp3-icon-paperclip::before {
  content: ""; }

.bp3-icon-paragraph::before {
  content: ""; }

.bp3-icon-path::before {
  content: ""; }

.bp3-icon-path-search::before {
  content: ""; }

.bp3-icon-pause::before {
  content: ""; }

.bp3-icon-people::before {
  content: ""; }

.bp3-icon-percentage::before {
  content: ""; }

.bp3-icon-person::before {
  content: ""; }

.bp3-icon-phone::before {
  content: "☎"; }

.bp3-icon-pie-chart::before {
  content: ""; }

.bp3-icon-pin::before {
  content: ""; }

.bp3-icon-pivot::before {
  content: ""; }

.bp3-icon-pivot-table::before {
  content: ""; }

.bp3-icon-play::before {
  content: ""; }

.bp3-icon-plus::before {
  content: "+"; }

.bp3-icon-polygon-filter::before {
  content: ""; }

.bp3-icon-power::before {
  content: ""; }

.bp3-icon-predictive-analysis::before {
  content: ""; }

.bp3-icon-prescription::before {
  content: ""; }

.bp3-icon-presentation::before {
  content: ""; }

.bp3-icon-print::before {
  content: "⎙"; }

.bp3-icon-projects::before {
  content: ""; }

.bp3-icon-properties::before {
  content: ""; }

.bp3-icon-property::before {
  content: ""; }

.bp3-icon-publish-function::before {
  content: ""; }

.bp3-icon-pulse::before {
  content: ""; }

.bp3-icon-random::before {
  content: ""; }

.bp3-icon-record::before {
  content: ""; }

.bp3-icon-redo::before {
  content: ""; }

.bp3-icon-refresh::before {
  content: ""; }

.bp3-icon-regression-chart::before {
  content: ""; }

.bp3-icon-remove::before {
  content: ""; }

.bp3-icon-remove-column::before {
  content: ""; }

.bp3-icon-remove-column-left::before {
  content: ""; }

.bp3-icon-remove-column-right::before {
  content: ""; }

.bp3-icon-remove-row-bottom::before {
  content: ""; }

.bp3-icon-remove-row-top::before {
  content: ""; }

.bp3-icon-repeat::before {
  content: ""; }

.bp3-icon-reset::before {
  content: ""; }

.bp3-icon-resolve::before {
  content: ""; }

.bp3-icon-rig::before {
  content: ""; }

.bp3-icon-right-join::before {
  content: ""; }

.bp3-icon-ring::before {
  content: ""; }

.bp3-icon-rotate-document::before {
  content: ""; }

.bp3-icon-rotate-page::before {
  content: ""; }

.bp3-icon-satellite::before {
  content: ""; }

.bp3-icon-saved::before {
  content: ""; }

.bp3-icon-scatter-plot::before {
  content: ""; }

.bp3-icon-search::before {
  content: ""; }

.bp3-icon-search-around::before {
  content: ""; }

.bp3-icon-search-template::before {
  content: ""; }

.bp3-icon-search-text::before {
  content: ""; }

.bp3-icon-segmented-control::before {
  content: ""; }

.bp3-icon-select::before {
  content: ""; }

.bp3-icon-selection::before {
  content: "⦿"; }

.bp3-icon-send-to::before {
  content: ""; }

.bp3-icon-send-to-graph::before {
  content: ""; }

.bp3-icon-send-to-map::before {
  content: ""; }

.bp3-icon-series-add::before {
  content: ""; }

.bp3-icon-series-configuration::before {
  content: ""; }

.bp3-icon-series-derived::before {
  content: ""; }

.bp3-icon-series-filtered::before {
  content: ""; }

.bp3-icon-series-search::before {
  content: ""; }

.bp3-icon-settings::before {
  content: ""; }

.bp3-icon-share::before {
  content: ""; }

.bp3-icon-shield::before {
  content: ""; }

.bp3-icon-shop::before {
  content: ""; }

.bp3-icon-shopping-cart::before {
  content: ""; }

.bp3-icon-sim-card::before {
  content: ""; }

.bp3-icon-slash::before {
  content: ""; }

.bp3-icon-small-cross::before {
  content: ""; }

.bp3-icon-small-minus::before {
  content: ""; }

.bp3-icon-small-plus::before {
  content: ""; }

.bp3-icon-small-tick::before {
  content: ""; }

.bp3-icon-snowflake::before {
  content: ""; }

.bp3-icon-social-media::before {
  content: ""; }

.bp3-icon-sort::before {
  content: ""; }

.bp3-icon-sort-alphabetical::before {
  content: ""; }

.bp3-icon-sort-alphabetical-desc::before {
  content: ""; }

.bp3-icon-sort-asc::before {
  content: ""; }

.bp3-icon-sort-desc::before {
  content: ""; }

.bp3-icon-sort-numerical::before {
  content: ""; }

.bp3-icon-sort-numerical-desc::before {
  content: ""; }

.bp3-icon-split-columns::before {
  content: ""; }

.bp3-icon-square::before {
  content: ""; }

.bp3-icon-stacked-chart::before {
  content: ""; }

.bp3-icon-star::before {
  content: "★"; }

.bp3-icon-star-empty::before {
  content: "☆"; }

.bp3-icon-step-backward::before {
  content: ""; }

.bp3-icon-step-chart::before {
  content: ""; }

.bp3-icon-step-forward::before {
  content: ""; }

.bp3-icon-stop::before {
  content: ""; }

.bp3-icon-strikethrough::before {
  content: ""; }

.bp3-icon-style::before {
  content: ""; }

.bp3-icon-swap-horizontal::before {
  content: ""; }

.bp3-icon-swap-vertical::before {
  content: ""; }

.bp3-icon-symbol-circle::before {
  content: ""; }

.bp3-icon-symbol-cross::before {
  content: ""; }

.bp3-icon-symbol-diamond::before {
  content: ""; }

.bp3-icon-symbol-square::before {
  content: ""; }

.bp3-icon-symbol-triangle-down::before {
  content: ""; }

.bp3-icon-symbol-triangle-up::before {
  content: ""; }

.bp3-icon-tag::before {
  content: ""; }

.bp3-icon-take-action::before {
  content: ""; }

.bp3-icon-taxi::before {
  content: ""; }

.bp3-icon-text-highlight::before {
  content: ""; }

.bp3-icon-th::before {
  content: ""; }

.bp3-icon-th-derived::before {
  content: ""; }

.bp3-icon-th-disconnect::before {
  content: ""; }

.bp3-icon-th-filtered::before {
  content: ""; }

.bp3-icon-th-list::before {
  content: ""; }

.bp3-icon-thumbs-down::before {
  content: ""; }

.bp3-icon-thumbs-up::before {
  content: ""; }

.bp3-icon-tick::before {
  content: "✓"; }

.bp3-icon-tick-circle::before {
  content: ""; }

.bp3-icon-time::before {
  content: "⏲"; }

.bp3-icon-timeline-area-chart::before {
  content: ""; }

.bp3-icon-timeline-bar-chart::before {
  content: ""; }

.bp3-icon-timeline-events::before {
  content: ""; }

.bp3-icon-timeline-line-chart::before {
  content: ""; }

.bp3-icon-tint::before {
  content: ""; }

.bp3-icon-torch::before {
  content: ""; }

.bp3-icon-train::before {
  content: ""; }

.bp3-icon-translate::before {
  content: ""; }

.bp3-icon-trash::before {
  content: ""; }

.bp3-icon-tree::before {
  content: ""; }

.bp3-icon-trending-down::before {
  content: ""; }

.bp3-icon-trending-up::before {
  content: ""; }

.bp3-icon-two-columns::before {
  content: ""; }

.bp3-icon-underline::before {
  content: "⎁"; }

.bp3-icon-undo::before {
  content: "⎌"; }

.bp3-icon-ungroup-objects::before {
  content: ""; }

.bp3-icon-unknown-vehicle::before {
  content: ""; }

.bp3-icon-unlock::before {
  content: ""; }

.bp3-icon-unpin::before {
  content: ""; }

.bp3-icon-unresolve::before {
  content: ""; }

.bp3-icon-updated::before {
  content: ""; }

.bp3-icon-upload::before {
  content: ""; }

.bp3-icon-user::before {
  content: ""; }

.bp3-icon-variable::before {
  content: ""; }

.bp3-icon-vertical-bar-chart-asc::before {
  content: ""; }

.bp3-icon-vertical-bar-chart-desc::before {
  content: ""; }

.bp3-icon-vertical-distribution::before {
  content: ""; }

.bp3-icon-video::before {
  content: ""; }

.bp3-icon-volume-down::before {
  content: ""; }

.bp3-icon-volume-off::before {
  content: ""; }

.bp3-icon-volume-up::before {
  content: ""; }

.bp3-icon-walk::before {
  content: ""; }

.bp3-icon-warning-sign::before {
  content: ""; }

.bp3-icon-waterfall-chart::before {
  content: ""; }

.bp3-icon-widget::before {
  content: ""; }

.bp3-icon-widget-button::before {
  content: ""; }

.bp3-icon-widget-footer::before {
  content: ""; }

.bp3-icon-widget-header::before {
  content: ""; }

.bp3-icon-wrench::before {
  content: ""; }

.bp3-icon-zoom-in::before {
  content: ""; }

.bp3-icon-zoom-out::before {
  content: ""; }

.bp3-icon-zoom-to-fit::before {
  content: ""; }

.bp3-submenu > .bp3-popover-wrapper {
  display: block; }

.bp3-submenu .bp3-popover-target {
  display: block; }

.bp3-submenu.bp3-popover {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 5px; }

.bp3-submenu.bp3-popover > .bp3-popover-content {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }

.bp3-dark .bp3-submenu.bp3-popover, .bp3-submenu.bp3-popover.bp3-dark {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-dark .bp3-submenu.bp3-popover > .bp3-popover-content, .bp3-submenu.bp3-popover.bp3-dark > .bp3-popover-content {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-menu {
  margin: 0;
  border-radius: 3px;
  background: #ffffff;
  min-width: 180px;
  padding: 5px;
  list-style: none;
  text-align: left;
  color: #182026; }

.bp3-menu-divider {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(16, 22, 26, 0.15); }

.bp3-dark .bp3-menu-divider {
  border-top-color: rgba(255, 255, 255, 0.15); }

.bp3-menu-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 2px;
  padding: 5px 7px;
  text-decoration: none;
  line-height: 20px;
  color: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-menu-item > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-menu-item > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-menu-item::before,
.bp3-menu-item > * {
  margin-right: 7px; }

.bp3-menu-item:empty::before,
.bp3-menu-item > :last-child {
  margin-right: 0; }

.bp3-menu-item > .bp3-fill {
  word-break: break-word; }

.bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
  background-color: rgba(167, 182, 194, 0.3);
  cursor: pointer;
  text-decoration: none; }

.bp3-menu-item.bp3-disabled {
  background-color: inherit;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-dark .bp3-menu-item {
  color: inherit; }

.bp3-dark .bp3-menu-item:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
  background-color: rgba(138, 155, 168, 0.15);
  color: inherit; }

.bp3-dark .bp3-menu-item.bp3-disabled {
  background-color: inherit;
  color: rgba(167, 182, 194, 0.6); }

.bp3-menu-item.bp3-intent-primary {
  color: #106ba3; }

.bp3-menu-item.bp3-intent-primary .bp3-icon {
  color: inherit; }

.bp3-menu-item.bp3-intent-primary::before, .bp3-menu-item.bp3-intent-primary::after,
.bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
  color: #106ba3; }

.bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: #137cbd; }

.bp3-menu-item.bp3-intent-primary:active {
  background-color: #106ba3; }

.bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
.bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
.bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary:active, .bp3-menu-item.bp3-intent-primary:active::before, .bp3-menu-item.bp3-intent-primary:active::after,
.bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-menu-item.bp3-intent-primary.bp3-active::after,
.bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-menu-item.bp3-intent-success {
  color: #0d8050; }

.bp3-menu-item.bp3-intent-success .bp3-icon {
  color: inherit; }

.bp3-menu-item.bp3-intent-success::before, .bp3-menu-item.bp3-intent-success::after,
.bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
  color: #0d8050; }

.bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success.bp3-active {
  background-color: #0f9960; }

.bp3-menu-item.bp3-intent-success:active {
  background-color: #0d8050; }

.bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-menu-item.bp3-intent-success:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
.bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
.bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success:active, .bp3-menu-item.bp3-intent-success:active::before, .bp3-menu-item.bp3-intent-success:active::after,
.bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-menu-item.bp3-intent-success.bp3-active::after,
.bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-menu-item.bp3-intent-warning {
  color: #bf7326; }

.bp3-menu-item.bp3-intent-warning .bp3-icon {
  color: inherit; }

.bp3-menu-item.bp3-intent-warning::before, .bp3-menu-item.bp3-intent-warning::after,
.bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
  color: #bf7326; }

.bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning.bp3-active {
  background-color: #d9822b; }

.bp3-menu-item.bp3-intent-warning:active {
  background-color: #bf7326; }

.bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
.bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
.bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning:active, .bp3-menu-item.bp3-intent-warning:active::before, .bp3-menu-item.bp3-intent-warning:active::after,
.bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-menu-item.bp3-intent-warning.bp3-active::after,
.bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-menu-item.bp3-intent-danger {
  color: #c23030; }

.bp3-menu-item.bp3-intent-danger .bp3-icon {
  color: inherit; }

.bp3-menu-item.bp3-intent-danger::before, .bp3-menu-item.bp3-intent-danger::after,
.bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
  color: #c23030; }

.bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger.bp3-active {
  background-color: #db3737; }

.bp3-menu-item.bp3-intent-danger:active {
  background-color: #c23030; }

.bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
.bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
.bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger:active, .bp3-menu-item.bp3-intent-danger:active::before, .bp3-menu-item.bp3-intent-danger:active::after,
.bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-menu-item.bp3-intent-danger.bp3-active::after,
.bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-menu-item::before {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 7px; }

.bp3-menu-item::before,
.bp3-menu-item > .bp3-icon {
  margin-top: 2px;
  color: #5c7080; }

.bp3-menu-item .bp3-menu-item-label {
  color: #5c7080; }

.bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
  color: inherit; }

.bp3-menu-item.bp3-active, .bp3-menu-item:active {
  background-color: rgba(115, 134, 148, 0.3); }

.bp3-menu-item.bp3-disabled {
  outline: none !important;
  background-color: inherit !important;
  cursor: not-allowed !important;
  color: rgba(92, 112, 128, 0.6) !important; }

.bp3-menu-item.bp3-disabled::before,
.bp3-menu-item.bp3-disabled > .bp3-icon,
.bp3-menu-item.bp3-disabled .bp3-menu-item-label {
  color: rgba(92, 112, 128, 0.6) !important; }

.bp3-large .bp3-menu-item {
  padding: 9px 7px;
  line-height: 22px;
  font-size: 16px; }

.bp3-large .bp3-menu-item .bp3-icon {
  margin-top: 3px; }

.bp3-large .bp3-menu-item::before {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-top: 1px;
  margin-right: 10px; }

button.bp3-menu-item {
  border: none;
  background: none;
  width: 100%;
  text-align: left; }

.bp3-menu-header {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  cursor: default;
  padding-left: 2px; }

.bp3-dark .bp3-menu-header {
  border-top-color: rgba(255, 255, 255, 0.15); }

.bp3-menu-header:first-of-type {
  border-top: none; }

.bp3-menu-header > h6 {
  color: #182026;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  margin: 0;
  padding: 10px 7px 0 1px;
  line-height: 17px; }

.bp3-dark .bp3-menu-header > h6 {
  color: #f5f8fa; }

.bp3-menu-header:first-of-type > h6 {
  padding-top: 0; }

.bp3-large .bp3-menu-header > h6 {
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 18px; }

.bp3-large .bp3-menu-header:first-of-type > h6 {
  padding-top: 0; }

.bp3-dark .bp3-menu {
  background: #30404d;
  color: #f5f8fa; }

.bp3-dark .bp3-menu-item.bp3-intent-primary {
  color: #48aff0; }

.bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-icon {
  color: inherit; }

.bp3-dark .bp3-menu-item.bp3-intent-primary::before, .bp3-dark .bp3-menu-item.bp3-intent-primary::after,
.bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
  color: #48aff0; }

.bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: #137cbd; }

.bp3-dark .bp3-menu-item.bp3-intent-primary:active {
  background-color: #106ba3; }

.bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
.bp3-dark .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
.bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label,
.bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary:active, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::after,
.bp3-dark .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::after,
.bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-dark .bp3-menu-item.bp3-intent-success {
  color: #3dcc91; }

.bp3-dark .bp3-menu-item.bp3-intent-success .bp3-icon {
  color: inherit; }

.bp3-dark .bp3-menu-item.bp3-intent-success::before, .bp3-dark .bp3-menu-item.bp3-intent-success::after,
.bp3-dark .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
  color: #3dcc91; }

.bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active {
  background-color: #0f9960; }

.bp3-dark .bp3-menu-item.bp3-intent-success:active {
  background-color: #0d8050; }

.bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
.bp3-dark .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
.bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label,
.bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success:active, .bp3-dark .bp3-menu-item.bp3-intent-success:active::before, .bp3-dark .bp3-menu-item.bp3-intent-success:active::after,
.bp3-dark .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::after,
.bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-dark .bp3-menu-item.bp3-intent-warning {
  color: #ffb366; }

.bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-icon {
  color: inherit; }

.bp3-dark .bp3-menu-item.bp3-intent-warning::before, .bp3-dark .bp3-menu-item.bp3-intent-warning::after,
.bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
  color: #ffb366; }

.bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active {
  background-color: #d9822b; }

.bp3-dark .bp3-menu-item.bp3-intent-warning:active {
  background-color: #bf7326; }

.bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
.bp3-dark .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
.bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label,
.bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning:active, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::after,
.bp3-dark .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::after,
.bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-dark .bp3-menu-item.bp3-intent-danger {
  color: #ff7373; }

.bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-icon {
  color: inherit; }

.bp3-dark .bp3-menu-item.bp3-intent-danger::before, .bp3-dark .bp3-menu-item.bp3-intent-danger::after,
.bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
  color: #ff7373; }

.bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active {
  background-color: #db3737; }

.bp3-dark .bp3-menu-item.bp3-intent-danger:active {
  background-color: #c23030; }

.bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
.bp3-dark .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
.bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label,
.bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger:active, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::after,
.bp3-dark .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::after,
.bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
  color: #ffffff; }

.bp3-dark .bp3-menu-item::before,
.bp3-dark .bp3-menu-item > .bp3-icon {
  color: #a7b6c2; }

.bp3-dark .bp3-menu-item .bp3-menu-item-label {
  color: #a7b6c2; }

.bp3-dark .bp3-menu-item.bp3-active, .bp3-dark .bp3-menu-item:active {
  background-color: rgba(138, 155, 168, 0.3); }

.bp3-dark .bp3-menu-item.bp3-disabled {
  color: rgba(167, 182, 194, 0.6) !important; }

.bp3-dark .bp3-menu-item.bp3-disabled::before,
.bp3-dark .bp3-menu-item.bp3-disabled > .bp3-icon,
.bp3-dark .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
  color: rgba(167, 182, 194, 0.6) !important; }

.bp3-dark .bp3-menu-divider,
.bp3-dark .bp3-menu-header {
  border-color: rgba(255, 255, 255, 0.15); }

.bp3-dark .bp3-menu-header > h6 {
  color: #f5f8fa; }

.bp3-label .bp3-menu {
  margin-top: 5px; }

.bp3-navbar {
  position: relative;
  z-index: 10;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  padding: 0 15px; }

.bp3-navbar.bp3-dark,
.bp3-dark .bp3-navbar {
  background-color: #394b59; }

.bp3-navbar.bp3-dark {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-navbar {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-navbar.bp3-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0; }

.bp3-navbar-heading {
  margin-right: 15px;
  font-size: 16px; }

.bp3-navbar-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px; }

.bp3-navbar-group.bp3-align-left {
  float: left; }

.bp3-navbar-group.bp3-align-right {
  float: right; }

.bp3-navbar-divider {
  margin: 0 10px;
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  height: 20px; }

.bp3-dark .bp3-navbar-divider {
  border-left-color: rgba(255, 255, 255, 0.15); }

.bp3-non-ideal-state {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center; }

.bp3-non-ideal-state > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-non-ideal-state > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-non-ideal-state::before,
.bp3-non-ideal-state > * {
  margin-bottom: 20px; }

.bp3-non-ideal-state:empty::before,
.bp3-non-ideal-state > :last-child {
  margin-bottom: 0; }

.bp3-non-ideal-state > * {
  max-width: 400px; }

.bp3-non-ideal-state-visual {
  color: rgba(92, 112, 128, 0.6);
  font-size: 60px; }

.bp3-dark .bp3-non-ideal-state-visual {
  color: rgba(167, 182, 194, 0.6); }

.bp3-overflow-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  min-width: 0; }

.bp3-overflow-list-spacer {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 1px; }

body.bp3-overlay-open {
  overflow: hidden; }

.bp3-overlay {
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20; }

.bp3-overlay:not(.bp3-overlay-open) {
  pointer-events: none; }

.bp3-overlay.bp3-overlay-container {
  position: fixed;
  overflow: hidden; }

.bp3-overlay.bp3-overlay-container.bp3-overlay-inline {
  position: absolute; }

.bp3-overlay.bp3-overlay-scroll-container {
  position: fixed;
  overflow: auto; }

.bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {
  position: absolute; }

.bp3-overlay.bp3-overlay-inline {
  display: inline;
  overflow: visible; }

.bp3-overlay-content {
  position: fixed;
  z-index: 20; }

.bp3-overlay-inline .bp3-overlay-content,
.bp3-overlay-scroll-container .bp3-overlay-content {
  position: absolute; }

.bp3-overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-overlay-backdrop.bp3-overlay-enter, .bp3-overlay-backdrop.bp3-overlay-appear {
  opacity: 0; }

.bp3-overlay-backdrop.bp3-overlay-enter-active, .bp3-overlay-backdrop.bp3-overlay-appear-active {
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-overlay-backdrop.bp3-overlay-exit {
  opacity: 1; }

.bp3-overlay-backdrop.bp3-overlay-exit-active {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-overlay-backdrop:focus {
  outline: none; }

.bp3-overlay-inline .bp3-overlay-backdrop {
  position: absolute; }

.bp3-panel-stack {
  position: relative;
  overflow: hidden; }

.bp3-panel-stack-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  -webkit-box-shadow: 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px rgba(16, 22, 26, 0.15);
  height: 30px; }

.bp3-dark .bp3-panel-stack-header {
  -webkit-box-shadow: 0 1px rgba(255, 255, 255, 0.15);
  box-shadow: 0 1px rgba(255, 255, 255, 0.15); }

.bp3-panel-stack-header > span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }

.bp3-panel-stack-header .bp3-heading {
  margin: 0 5px; }

.bp3-button.bp3-panel-stack-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap; }

.bp3-button.bp3-panel-stack-header-back .bp3-icon {
  margin: 0 2px; }

.bp3-panel-stack-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: -1px;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  background-color: #ffffff;
  overflow-y: auto; }

.bp3-dark .bp3-panel-stack-view {
  background-color: #30404d; }

.bp3-panel-stack-push .bp3-panel-stack-enter, .bp3-panel-stack-push .bp3-panel-stack-appear {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0; }

.bp3-panel-stack-push .bp3-panel-stack-enter-active, .bp3-panel-stack-push .bp3-panel-stack-appear-active {
  -webkit-transform: translate(0%);
  transform: translate(0%);
  opacity: 1;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-panel-stack-push .bp3-panel-stack-exit {
  -webkit-transform: translate(0%);
  transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-push .bp3-panel-stack-exit-active {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-enter, .bp3-panel-stack-pop .bp3-panel-stack-appear {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-enter-active, .bp3-panel-stack-pop .bp3-panel-stack-appear-active {
  -webkit-transform: translate(0%);
  transform: translate(0%);
  opacity: 1;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-exit {
  -webkit-transform: translate(0%);
  transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-pop .bp3-panel-stack-exit-active {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-popover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  -webkit-transform: scale(1);
  transform: scale(1);
  display: inline-block;
  z-index: 20;
  border-radius: 3px; }

.bp3-popover .bp3-popover-arrow {
  position: absolute;
  width: 30px;
  height: 30px; }

.bp3-popover .bp3-popover-arrow::before {
  margin: 5px;
  width: 20px;
  height: 20px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover {
  margin-top: -17px;
  margin-bottom: 17px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
  bottom: -11px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover {
  margin-left: 17px; }

.bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
  left: -11px; }

.bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow svg {
  -webkit-transform: rotate(0);
  transform: rotate(0); }

.bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover {
  margin-top: 17px; }

.bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
  top: -11px; }

.bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover {
  margin-right: 17px;
  margin-left: -17px; }

.bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
  right: -11px; }

.bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.bp3-tether-element-attached-middle > .bp3-popover > .bp3-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.bp3-tether-element-attached-center > .bp3-popover > .bp3-popover-arrow {
  right: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%); }

.bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
  top: -0.3934px; }

.bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
  right: -0.3934px; }

.bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
  left: -0.3934px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
  bottom: -0.3934px; }

.bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-popover {
  -webkit-transform-origin: top left;
  transform-origin: top left; }

.bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-popover {
  -webkit-transform-origin: top center;
  transform-origin: top center; }

.bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-popover {
  -webkit-transform-origin: top right;
  transform-origin: top right; }

.bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-popover {
  -webkit-transform-origin: center left;
  transform-origin: center left; }

.bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-popover {
  -webkit-transform-origin: center center;
  transform-origin: center center; }

.bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-popover {
  -webkit-transform-origin: center right;
  transform-origin: center right; }

.bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-popover {
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left; }

.bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-popover {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center; }

.bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-popover {
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right; }

.bp3-popover .bp3-popover-content {
  background: #ffffff;
  color: inherit; }

.bp3-popover .bp3-popover-arrow::before {
  -webkit-box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
  box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }

.bp3-popover .bp3-popover-arrow-border {
  fill: #10161a;
  fill-opacity: 0.1; }

.bp3-popover .bp3-popover-arrow-fill {
  fill: #ffffff; }

.bp3-popover-enter > .bp3-popover, .bp3-popover-appear > .bp3-popover {
  -webkit-transform: scale(0.3);
  transform: scale(0.3); }

.bp3-popover-enter-active > .bp3-popover, .bp3-popover-appear-active > .bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-popover-exit > .bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-popover-exit-active > .bp3-popover {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-popover .bp3-popover-content {
  position: relative;
  border-radius: 3px; }

.bp3-popover.bp3-popover-content-sizing .bp3-popover-content {
  max-width: 350px;
  padding: 20px; }

.bp3-popover-target + .bp3-overlay .bp3-popover.bp3-popover-content-sizing {
  width: 350px; }

.bp3-popover.bp3-minimal {
  margin: 0 !important; }

.bp3-popover.bp3-minimal .bp3-popover-arrow {
  display: none; }

.bp3-popover.bp3-minimal.bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-popover-enter > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear > .bp3-popover.bp3-minimal.bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-popover-enter-active > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear-active > .bp3-popover.bp3-minimal.bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-popover-exit > .bp3-popover.bp3-minimal.bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-popover-exit-active > .bp3-popover.bp3-minimal.bp3-popover {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-popover.bp3-dark,
.bp3-dark .bp3-popover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-popover.bp3-dark .bp3-popover-content,
.bp3-dark .bp3-popover .bp3-popover-content {
  background: #30404d;
  color: inherit; }

.bp3-popover.bp3-dark .bp3-popover-arrow::before,
.bp3-dark .bp3-popover .bp3-popover-arrow::before {
  -webkit-box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4);
  box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }

.bp3-popover.bp3-dark .bp3-popover-arrow-border,
.bp3-dark .bp3-popover .bp3-popover-arrow-border {
  fill: #10161a;
  fill-opacity: 0.2; }

.bp3-popover.bp3-dark .bp3-popover-arrow-fill,
.bp3-dark .bp3-popover .bp3-popover-arrow-fill {
  fill: #30404d; }

.bp3-popover-arrow::before {
  display: block;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
  content: ""; }

.bp3-tether-pinned .bp3-popover-arrow {
  display: none; }

.bp3-popover-backdrop {
  background: rgba(255, 255, 255, 0); }

.bp3-transition-container {
  opacity: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 20; }

.bp3-transition-container.bp3-popover-enter, .bp3-transition-container.bp3-popover-appear {
  opacity: 0; }

.bp3-transition-container.bp3-popover-enter-active, .bp3-transition-container.bp3-popover-appear-active {
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-transition-container.bp3-popover-exit {
  opacity: 1; }

.bp3-transition-container.bp3-popover-exit-active {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-transition-container:focus {
  outline: none; }

.bp3-transition-container.bp3-popover-leave .bp3-popover-content {
  pointer-events: none; }

.bp3-transition-container[data-x-out-of-boundaries] {
  display: none; }

span.bp3-popover-target {
  display: inline-block; }

.bp3-popover-wrapper.bp3-fill {
  width: 100%; }

.bp3-portal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

@-webkit-keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.bp3-progress-bar {
  display: block;
  position: relative;
  border-radius: 40px;
  background: rgba(92, 112, 128, 0.2);
  width: 100%;
  height: 8px;
  overflow: hidden; }

.bp3-progress-bar .bp3-progress-meter {
  position: absolute;
  border-radius: 40px;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
  background-color: rgba(92, 112, 128, 0.8);
  background-size: 30px 30px;
  width: 100%;
  height: 100%;
  -webkit-transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-progress-bar:not(.bp3-no-animation):not(.bp3-no-stripes) .bp3-progress-meter {
  animation: linear-progress-bar-stripes 300ms linear infinite reverse; }

.bp3-progress-bar.bp3-no-stripes .bp3-progress-meter {
  background-image: none; }

.bp3-dark .bp3-progress-bar {
  background: rgba(16, 22, 26, 0.5); }

.bp3-dark .bp3-progress-bar .bp3-progress-meter {
  background-color: #8a9ba8; }

.bp3-progress-bar.bp3-intent-primary .bp3-progress-meter {
  background-color: #137cbd; }

.bp3-progress-bar.bp3-intent-success .bp3-progress-meter {
  background-color: #0f9960; }

.bp3-progress-bar.bp3-intent-warning .bp3-progress-meter {
  background-color: #d9822b; }

.bp3-progress-bar.bp3-intent-danger .bp3-progress-meter {
  background-color: #db3737; }

@-webkit-keyframes skeleton-glow {
  from {
    border-color: rgba(206, 217, 224, 0.2);
    background: rgba(206, 217, 224, 0.2); }
  to {
    border-color: rgba(92, 112, 128, 0.2);
    background: rgba(92, 112, 128, 0.2); } }

@keyframes skeleton-glow {
  from {
    border-color: rgba(206, 217, 224, 0.2);
    background: rgba(206, 217, 224, 0.2); }
  to {
    border-color: rgba(92, 112, 128, 0.2);
    background: rgba(92, 112, 128, 0.2); } }

.bp3-skeleton {
  border-color: rgba(206, 217, 224, 0.2) !important;
  border-radius: 2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: rgba(206, 217, 224, 0.2);
  background-clip: padding-box !important;
  cursor: default;
  color: transparent !important;
  -webkit-animation: 1000ms linear infinite alternate skeleton-glow;
  animation: 1000ms linear infinite alternate skeleton-glow;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-skeleton::before, .bp3-skeleton::after,
.bp3-skeleton * {
  visibility: hidden !important; }

.bp3-slider {
  width: 100%;
  min-width: 150px;
  height: 40px;
  position: relative;
  outline: none;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-slider:hover {
  cursor: pointer; }

.bp3-slider:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-slider.bp3-disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.bp3-slider.bp3-slider-unlabeled {
  height: 16px; }

.bp3-slider-track,
.bp3-slider-progress {
  top: 5px;
  right: 0;
  left: 0;
  height: 6px;
  position: absolute; }

.bp3-slider-track {
  border-radius: 3px;
  overflow: hidden; }

.bp3-slider-progress {
  background: rgba(92, 112, 128, 0.2); }

.bp3-dark .bp3-slider-progress {
  background: rgba(16, 22, 26, 0.5); }

.bp3-slider-progress.bp3-intent-primary {
  background-color: #137cbd; }

.bp3-slider-progress.bp3-intent-success {
  background-color: #0f9960; }

.bp3-slider-progress.bp3-intent-warning {
  background-color: #d9822b; }

.bp3-slider-progress.bp3-intent-danger {
  background-color: #db3737; }

.bp3-slider-handle {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  cursor: pointer;
  width: 16px;
  height: 16px; }

.bp3-slider-handle:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #ebf1f5; }

.bp3-slider-handle:active, .bp3-slider-handle.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #d8e1e8;
  background-image: none; }

.bp3-slider-handle:disabled, .bp3-slider-handle.bp3-disabled {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  background-image: none;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-slider-handle:disabled.bp3-active, .bp3-slider-handle:disabled.bp3-active:hover, .bp3-slider-handle.bp3-disabled.bp3-active, .bp3-slider-handle.bp3-disabled.bp3-active:hover {
  background: rgba(206, 217, 224, 0.7); }

.bp3-slider-handle:focus {
  z-index: 1; }

.bp3-slider-handle:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  background-color: #ebf1f5;
  z-index: 2;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  cursor: -webkit-grab;
  cursor: grab; }

.bp3-slider-handle.bp3-active {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #d8e1e8;
  background-image: none;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 1px rgba(16, 22, 26, 0.1);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 1px rgba(16, 22, 26, 0.1);
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-disabled .bp3-slider-handle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #bfccd6;
  pointer-events: none; }

.bp3-dark .bp3-slider-handle {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.05)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }

.bp3-dark .bp3-slider-handle:hover, .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
  color: #f5f8fa; }

.bp3-dark .bp3-slider-handle:hover {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #30404d; }

.bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
  background-color: #202b33;
  background-image: none; }

.bp3-dark .bp3-slider-handle:disabled, .bp3-dark .bp3-slider-handle.bp3-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(57, 75, 89, 0.5);
  background-image: none;
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-slider-handle:disabled.bp3-active, .bp3-dark .bp3-slider-handle.bp3-disabled.bp3-active {
  background: rgba(57, 75, 89, 0.7); }

.bp3-dark .bp3-slider-handle .bp3-button-spinner .bp3-spinner-head {
  background: rgba(16, 22, 26, 0.5);
  stroke: #8a9ba8; }

.bp3-dark .bp3-slider-handle, .bp3-dark .bp3-slider-handle:hover {
  background-color: #394b59; }

.bp3-dark .bp3-slider-handle.bp3-active {
  background-color: #293742; }

.bp3-dark .bp3-disabled .bp3-slider-handle {
  border-color: #5c7080;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #5c7080; }

.bp3-slider-handle .bp3-slider-label {
  margin-left: 8px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  background: #394b59;
  color: #f5f8fa; }

.bp3-dark .bp3-slider-handle .bp3-slider-label {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  background: #e1e8ed;
  color: #394b59; }

.bp3-disabled .bp3-slider-handle .bp3-slider-label {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-slider-handle.bp3-start, .bp3-slider-handle.bp3-end {
  width: 8px; }

.bp3-slider-handle.bp3-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.bp3-slider-handle.bp3-end {
  margin-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.bp3-slider-handle.bp3-end .bp3-slider-label {
  margin-left: 0; }

.bp3-slider-label {
  -webkit-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
  display: inline-block;
  position: absolute;
  padding: 2px 5px;
  vertical-align: top;
  line-height: 1;
  font-size: 12px; }

.bp3-slider.bp3-vertical {
  width: 40px;
  min-width: 40px;
  height: 150px; }

.bp3-slider.bp3-vertical .bp3-slider-track,
.bp3-slider.bp3-vertical .bp3-slider-progress {
  top: 0;
  bottom: 0;
  left: 5px;
  width: 6px;
  height: auto; }

.bp3-slider.bp3-vertical .bp3-slider-progress {
  top: auto; }

.bp3-slider.bp3-vertical .bp3-slider-label {
  -webkit-transform: translate(20px, 50%);
  transform: translate(20px, 50%); }

.bp3-slider.bp3-vertical .bp3-slider-handle {
  top: auto; }

.bp3-slider.bp3-vertical .bp3-slider-handle .bp3-slider-label {
  margin-top: -8px;
  margin-left: 0; }

.bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end, .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
  margin-left: 0;
  width: 16px;
  height: 8px; }

.bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px; }

.bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start .bp3-slider-label {
  -webkit-transform: translate(20px);
  transform: translate(20px); }

.bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end {
  margin-bottom: 8px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

@-webkit-keyframes pt-spinner-animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes pt-spinner-animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.bp3-spinner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle; }

.bp3-spinner svg {
  display: block; }

.bp3-spinner path {
  fill-opacity: 0; }

.bp3-spinner .bp3-spinner-head {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  stroke: rgba(92, 112, 128, 0.8);
  stroke-linecap: round; }

.bp3-spinner .bp3-spinner-track {
  stroke: rgba(92, 112, 128, 0.2); }

.bp3-spinner-animation {
  -webkit-animation: pt-spinner-animation 500ms linear infinite;
  animation: pt-spinner-animation 500ms linear infinite; }

.bp3-no-spin > .bp3-spinner-animation {
  -webkit-animation: none;
  animation: none; }

.bp3-dark .bp3-spinner .bp3-spinner-head {
  stroke: #8a9ba8; }

.bp3-dark .bp3-spinner .bp3-spinner-track {
  stroke: rgba(16, 22, 26, 0.5); }

.bp3-spinner.bp3-intent-primary .bp3-spinner-head {
  stroke: #137cbd; }

.bp3-spinner.bp3-intent-success .bp3-spinner-head {
  stroke: #0f9960; }

.bp3-spinner.bp3-intent-warning .bp3-spinner-head {
  stroke: #d9822b; }

.bp3-spinner.bp3-intent-danger .bp3-spinner-head {
  stroke: #db3737; }

.bp3-tabs.bp3-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.bp3-tabs.bp3-vertical > .bp3-tab-list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab {
  border-radius: 3px;
  width: 100%;
  padding: 0 10px; }

.bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"] {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(19, 124, 189, 0.2); }

.bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab-indicator-wrapper .bp3-tab-indicator {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 3px;
  background-color: rgba(19, 124, 189, 0.2);
  height: auto; }

.bp3-tabs.bp3-vertical > .bp3-tab-panel {
  margin-top: 0;
  padding-left: 20px; }

.bp3-tab-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  margin: 0;
  border: none;
  padding: 0;
  list-style: none; }

.bp3-tab-list > *:not(:last-child) {
  margin-right: 20px; }

.bp3-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  vertical-align: top;
  line-height: 30px;
  color: #182026;
  font-size: 14px; }

.bp3-tab a {
  display: block;
  text-decoration: none;
  color: inherit; }

.bp3-tab-indicator-wrapper ~ .bp3-tab {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent !important; }

.bp3-tab[aria-disabled="true"] {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-tab[aria-selected="true"] {
  border-radius: 0;
  -webkit-box-shadow: inset 0 -3px 0 #106ba3;
  box-shadow: inset 0 -3px 0 #106ba3; }

.bp3-tab[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
  color: #106ba3; }

.bp3-tab:focus {
  -moz-outline-radius: 0; }

.bp3-large > .bp3-tab {
  line-height: 40px;
  font-size: 16px; }

.bp3-tab-panel {
  margin-top: 20px; }

.bp3-tab-panel[aria-hidden="true"] {
  display: none; }

.bp3-tab-indicator-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(0), translateY(0);
  transform: translateX(0), translateY(0);
  -webkit-transition: height, width, -webkit-transform;
  transition: height, width, -webkit-transform;
  transition: height, transform, width;
  transition: height, transform, width, -webkit-transform;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  pointer-events: none; }

.bp3-tab-indicator-wrapper .bp3-tab-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #106ba3;
  height: 3px; }

.bp3-tab-indicator-wrapper.bp3-no-animation {
  -webkit-transition: none;
  transition: none; }

.bp3-dark .bp3-tab {
  color: #f5f8fa; }

.bp3-dark .bp3-tab[aria-disabled="true"] {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-tab[aria-selected="true"] {
  -webkit-box-shadow: inset 0 -3px 0 #48aff0;
  box-shadow: inset 0 -3px 0 #48aff0; }

.bp3-dark .bp3-tab[aria-selected="true"], .bp3-dark .bp3-tab:not([aria-disabled="true"]):hover {
  color: #48aff0; }

.bp3-dark .bp3-tab-indicator {
  background-color: #48aff0; }

.bp3-flex-expander {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1; }

.bp3-tag {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #5c7080;
  min-width: 20px;
  max-width: 100%;
  min-height: 20px;
  padding: 2px 6px;
  line-height: 16px;
  color: #f5f8fa;
  font-size: 12px; }

.bp3-tag.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-interactive:hover {
  background-color: rgba(92, 112, 128, 0.85); }

.bp3-tag.bp3-interactive.bp3-active, .bp3-tag.bp3-interactive:active {
  background-color: rgba(92, 112, 128, 0.7); }

.bp3-tag > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-tag > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-tag::before,
.bp3-tag > * {
  margin-right: 4px; }

.bp3-tag:empty::before,
.bp3-tag > :last-child {
  margin-right: 0; }

.bp3-tag:focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 0;
  -moz-outline-radius: 6px; }

.bp3-tag.bp3-round {
  border-radius: 30px;
  padding-right: 8px;
  padding-left: 8px; }

.bp3-dark .bp3-tag {
  background-color: #bfccd6;
  color: #182026; }

.bp3-dark .bp3-tag.bp3-interactive {
  cursor: pointer; }

.bp3-dark .bp3-tag.bp3-interactive:hover {
  background-color: rgba(191, 204, 214, 0.85); }

.bp3-dark .bp3-tag.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-interactive:active {
  background-color: rgba(191, 204, 214, 0.7); }

.bp3-dark .bp3-tag > .bp3-icon, .bp3-dark .bp3-tag .bp3-icon-standard, .bp3-dark .bp3-tag .bp3-icon-large {
  fill: currentColor; }

.bp3-tag > .bp3-icon, .bp3-tag .bp3-icon-standard, .bp3-tag .bp3-icon-large {
  fill: #ffffff; }

.bp3-tag.bp3-large,
.bp3-large .bp3-tag {
  min-width: 30px;
  min-height: 30px;
  padding: 0 10px;
  line-height: 20px;
  font-size: 14px; }

.bp3-tag.bp3-large::before,
.bp3-tag.bp3-large > *,
.bp3-large .bp3-tag::before,
.bp3-large .bp3-tag > * {
  margin-right: 7px; }

.bp3-tag.bp3-large:empty::before,
.bp3-tag.bp3-large > :last-child,
.bp3-large .bp3-tag:empty::before,
.bp3-large .bp3-tag > :last-child {
  margin-right: 0; }

.bp3-tag.bp3-large.bp3-round,
.bp3-large .bp3-tag.bp3-round {
  padding-right: 12px;
  padding-left: 12px; }

.bp3-tag.bp3-intent-primary {
  background: #137cbd;
  color: #ffffff; }

.bp3-tag.bp3-intent-primary.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-intent-primary.bp3-interactive:hover {
  background-color: rgba(19, 124, 189, 0.85); }

.bp3-tag.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-primary.bp3-interactive:active {
  background-color: rgba(19, 124, 189, 0.7); }

.bp3-tag.bp3-intent-success {
  background: #0f9960;
  color: #ffffff; }

.bp3-tag.bp3-intent-success.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-intent-success.bp3-interactive:hover {
  background-color: rgba(15, 153, 96, 0.85); }

.bp3-tag.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-success.bp3-interactive:active {
  background-color: rgba(15, 153, 96, 0.7); }

.bp3-tag.bp3-intent-warning {
  background: #d9822b;
  color: #ffffff; }

.bp3-tag.bp3-intent-warning.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-intent-warning.bp3-interactive:hover {
  background-color: rgba(217, 130, 43, 0.85); }

.bp3-tag.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-warning.bp3-interactive:active {
  background-color: rgba(217, 130, 43, 0.7); }

.bp3-tag.bp3-intent-danger {
  background: #db3737;
  color: #ffffff; }

.bp3-tag.bp3-intent-danger.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-intent-danger.bp3-interactive:hover {
  background-color: rgba(219, 55, 55, 0.85); }

.bp3-tag.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-danger.bp3-interactive:active {
  background-color: rgba(219, 55, 55, 0.7); }

.bp3-tag.bp3-fill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.bp3-tag.bp3-minimal > .bp3-icon, .bp3-tag.bp3-minimal .bp3-icon-standard, .bp3-tag.bp3-minimal .bp3-icon-large {
  fill: #5c7080; }

.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
  background-color: rgba(138, 155, 168, 0.2);
  color: #182026; }

.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
  background-color: rgba(92, 112, 128, 0.3); }

.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
  background-color: rgba(92, 112, 128, 0.4); }

.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
  color: #f5f8fa; }

.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
  cursor: pointer; }

.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
  background-color: rgba(191, 204, 214, 0.3); }

.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
  background-color: rgba(191, 204, 214, 0.4); }

.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) > .bp3-icon, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-large {
  fill: #a7b6c2; }

.bp3-tag.bp3-minimal.bp3-intent-primary {
  background-color: rgba(19, 124, 189, 0.15);
  color: #106ba3; }

.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
  background-color: rgba(19, 124, 189, 0.25); }

.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
  background-color: rgba(19, 124, 189, 0.35); }

.bp3-tag.bp3-minimal.bp3-intent-primary > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-large {
  fill: #137cbd; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary {
  background-color: rgba(19, 124, 189, 0.25);
  color: #48aff0; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
  cursor: pointer; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
  background-color: rgba(19, 124, 189, 0.35); }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
  background-color: rgba(19, 124, 189, 0.45); }

.bp3-tag.bp3-minimal.bp3-intent-success {
  background-color: rgba(15, 153, 96, 0.15);
  color: #0d8050; }

.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
  background-color: rgba(15, 153, 96, 0.25); }

.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
  background-color: rgba(15, 153, 96, 0.35); }

.bp3-tag.bp3-minimal.bp3-intent-success > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-large {
  fill: #0f9960; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success {
  background-color: rgba(15, 153, 96, 0.25);
  color: #3dcc91; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
  cursor: pointer; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
  background-color: rgba(15, 153, 96, 0.35); }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
  background-color: rgba(15, 153, 96, 0.45); }

.bp3-tag.bp3-minimal.bp3-intent-warning {
  background-color: rgba(217, 130, 43, 0.15);
  color: #bf7326; }

.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
  background-color: rgba(217, 130, 43, 0.25); }

.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
  background-color: rgba(217, 130, 43, 0.35); }

.bp3-tag.bp3-minimal.bp3-intent-warning > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-large {
  fill: #d9822b; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning {
  background-color: rgba(217, 130, 43, 0.25);
  color: #ffb366; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
  cursor: pointer; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
  background-color: rgba(217, 130, 43, 0.35); }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
  background-color: rgba(217, 130, 43, 0.45); }

.bp3-tag.bp3-minimal.bp3-intent-danger {
  background-color: rgba(219, 55, 55, 0.15);
  color: #c23030; }

.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
  cursor: pointer; }

.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
  background-color: rgba(219, 55, 55, 0.25); }

.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
  background-color: rgba(219, 55, 55, 0.35); }

.bp3-tag.bp3-minimal.bp3-intent-danger > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-large {
  fill: #db3737; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger {
  background-color: rgba(219, 55, 55, 0.25);
  color: #ff7373; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
  cursor: pointer; }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
  background-color: rgba(219, 55, 55, 0.35); }

.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
  background-color: rgba(219, 55, 55, 0.45); }

.bp3-tag-remove {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0.5;
  margin-top: -2px;
  margin-right: -6px !important;
  margin-bottom: -2px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
  padding-left: 0;
  color: inherit; }

.bp3-tag-remove:hover {
  opacity: 0.8;
  background: none;
  text-decoration: none; }

.bp3-tag-remove:active {
  opacity: 1; }

.bp3-tag-remove:empty::before {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: ""; }

.bp3-large .bp3-tag-remove {
  margin-right: -10px !important;
  padding: 5px;
  padding-left: 0; }

.bp3-large .bp3-tag-remove:empty::before {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal; }

.bp3-tag-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: text;
  height: auto;
  min-height: 30px;
  padding-right: 0;
  padding-left: 5px;
  line-height: inherit; }

.bp3-tag-input > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-tag-input > .bp3-tag-input-values {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-tag-input .bp3-tag-input-icon {
  margin-top: 7px;
  margin-right: 7px;
  margin-left: 2px;
  color: #5c7080; }

.bp3-tag-input .bp3-tag-input-values {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-top: 5px;
  margin-right: 7px;
  min-width: 0; }

.bp3-tag-input .bp3-tag-input-values > * {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.bp3-tag-input .bp3-tag-input-values > .bp3-fill {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1; }

.bp3-tag-input .bp3-tag-input-values::before,
.bp3-tag-input .bp3-tag-input-values > * {
  margin-right: 5px; }

.bp3-tag-input .bp3-tag-input-values:empty::before,
.bp3-tag-input .bp3-tag-input-values > :last-child {
  margin-right: 0; }

.bp3-tag-input .bp3-tag-input-values:first-child .bp3-input-ghost:first-child {
  padding-left: 5px; }

.bp3-tag-input .bp3-tag-input-values > * {
  margin-bottom: 5px; }

.bp3-tag-input .bp3-tag {
  overflow-wrap: break-word; }

.bp3-tag-input .bp3-tag.bp3-active {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 0;
  -moz-outline-radius: 6px; }

.bp3-tag-input .bp3-input-ghost {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 80px;
  line-height: 20px; }

.bp3-tag-input .bp3-input-ghost:disabled, .bp3-tag-input .bp3-input-ghost.bp3-disabled {
  cursor: not-allowed; }

.bp3-tag-input .bp3-button,
.bp3-tag-input .bp3-spinner {
  margin: 3px;
  margin-left: 0; }

.bp3-tag-input .bp3-button {
  min-width: 24px;
  min-height: 24px;
  padding: 0 7px; }

.bp3-tag-input.bp3-large {
  height: auto;
  min-height: 40px; }

.bp3-tag-input.bp3-large::before,
.bp3-tag-input.bp3-large > * {
  margin-right: 10px; }

.bp3-tag-input.bp3-large:empty::before,
.bp3-tag-input.bp3-large > :last-child {
  margin-right: 0; }

.bp3-tag-input.bp3-large .bp3-tag-input-icon {
  margin-top: 10px;
  margin-left: 5px; }

.bp3-tag-input.bp3-large .bp3-input-ghost {
  line-height: 30px; }

.bp3-tag-input.bp3-large .bp3-button {
  min-width: 30px;
  min-height: 30px;
  padding: 5px 10px;
  margin: 5px;
  margin-left: 0; }

.bp3-tag-input.bp3-large .bp3-spinner {
  margin: 8px;
  margin-left: 0; }

.bp3-tag-input.bp3-active {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #ffffff; }

.bp3-dark .bp3-tag-input .bp3-tag-input-icon, .bp3-tag-input.bp3-dark .bp3-tag-input-icon {
  color: #a7b6c2; }

.bp3-dark .bp3-tag-input .bp3-input-ghost, .bp3-tag-input.bp3-dark .bp3-input-ghost {
  color: #f5f8fa; }

.bp3-dark .bp3-tag-input .bp3-input-ghost::-webkit-input-placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::-webkit-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-tag-input .bp3-input-ghost::-ms-input-placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::-ms-input-placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-tag-input .bp3-input-ghost::placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::placeholder {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-tag-input.bp3-active, .bp3-tag-input.bp3-dark.bp3-active {
  -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background-color: rgba(16, 22, 26, 0.3); }

.bp3-input-ghost {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  padding: 0; }

.bp3-input-ghost::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-input-ghost::-ms-input-placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-input-ghost::placeholder {
  opacity: 1;
  color: rgba(92, 112, 128, 0.6); }

.bp3-input-ghost:focus {
  outline: none !important; }

.bp3-toast {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative !important;
  margin: 20px 0 0;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  background-color: #ffffff;
  min-width: 300px;
  max-width: 500px;
  pointer-events: all; }

.bp3-toast.bp3-toast-enter, .bp3-toast.bp3-toast-appear {
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px); }

.bp3-toast.bp3-toast-enter-active, .bp3-toast.bp3-toast-appear-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-toast.bp3-toast-enter ~ .bp3-toast, .bp3-toast.bp3-toast-appear ~ .bp3-toast {
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px); }

.bp3-toast.bp3-toast-enter-active ~ .bp3-toast, .bp3-toast.bp3-toast-appear-active ~ .bp3-toast {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-toast.bp3-toast-exit {
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0); }

.bp3-toast.bp3-toast-exit-active {
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-toast.bp3-toast-exit ~ .bp3-toast {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.bp3-toast.bp3-toast-exit-active ~ .bp3-toast {
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms; }

.bp3-toast .bp3-button-group {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 5px;
  padding-left: 0; }

.bp3-toast > .bp3-icon {
  margin: 12px;
  margin-right: 0;
  color: #5c7080; }

.bp3-toast.bp3-dark,
.bp3-dark .bp3-toast {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  background-color: #394b59; }

.bp3-toast.bp3-dark > .bp3-icon,
.bp3-dark .bp3-toast > .bp3-icon {
  color: #a7b6c2; }

.bp3-toast[class*="bp3-intent-"] a {
  color: rgba(255, 255, 255, 0.7); }

.bp3-toast[class*="bp3-intent-"] a:hover {
  color: #ffffff; }

.bp3-toast[class*="bp3-intent-"] > .bp3-icon {
  color: #ffffff; }

.bp3-toast[class*="bp3-intent-"] .bp3-button, .bp3-toast[class*="bp3-intent-"] .bp3-button::before,
.bp3-toast[class*="bp3-intent-"] .bp3-button .bp3-icon, .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
  color: rgba(255, 255, 255, 0.7) !important; }

.bp3-toast[class*="bp3-intent-"] .bp3-button:focus {
  outline-color: rgba(255, 255, 255, 0.5); }

.bp3-toast[class*="bp3-intent-"] .bp3-button:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
  color: #ffffff !important; }

.bp3-toast[class*="bp3-intent-"] .bp3-button:active {
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: #ffffff !important; }

.bp3-toast[class*="bp3-intent-"] .bp3-button::after {
  background: rgba(255, 255, 255, 0.3) !important; }

.bp3-toast.bp3-intent-primary {
  background-color: #137cbd;
  color: #ffffff; }

.bp3-toast.bp3-intent-success {
  background-color: #0f9960;
  color: #ffffff; }

.bp3-toast.bp3-intent-warning {
  background-color: #d9822b;
  color: #ffffff; }

.bp3-toast.bp3-intent-danger {
  background-color: #db3737;
  color: #ffffff; }

.bp3-toast-message {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 11px;
  word-break: break-word; }

.bp3-toast-container {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 40;
  overflow: hidden;
  padding: 0 20px 20px;
  pointer-events: none; }

.bp3-toast-container.bp3-toast-container-top {
  top: 0;
  bottom: auto; }

.bp3-toast-container.bp3-toast-container-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  top: auto;
  bottom: 0; }

.bp3-toast-container.bp3-toast-container-left {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.bp3-toast-container.bp3-toast-container-right {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active),
.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active) ~ .bp3-toast,
.bp3-toast-container-bottom .bp3-toast.bp3-toast-leave-active ~ .bp3-toast {
  -webkit-transform: translateY(60px);
  transform: translateY(60px); }

.bp3-tooltip {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-tooltip .bp3-popover-arrow {
  position: absolute;
  width: 22px;
  height: 22px; }

.bp3-tooltip .bp3-popover-arrow::before {
  margin: 4px;
  width: 14px;
  height: 14px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip {
  margin-top: -11px;
  margin-bottom: 11px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
  bottom: -8px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip {
  margin-left: 11px; }

.bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
  left: -8px; }

.bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow svg {
  -webkit-transform: rotate(0);
  transform: rotate(0); }

.bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip {
  margin-top: 11px; }

.bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
  top: -8px; }

.bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip {
  margin-right: 11px;
  margin-left: -11px; }

.bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
  right: -8px; }

.bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.bp3-tether-element-attached-middle > .bp3-tooltip > .bp3-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.bp3-tether-element-attached-center > .bp3-tooltip > .bp3-popover-arrow {
  right: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%); }

.bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
  top: -0.22183px; }

.bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
  right: -0.22183px; }

.bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
  left: -0.22183px; }

.bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
  bottom: -0.22183px; }

.bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-tooltip {
  -webkit-transform-origin: top left;
  transform-origin: top left; }

.bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-tooltip {
  -webkit-transform-origin: top center;
  transform-origin: top center; }

.bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-tooltip {
  -webkit-transform-origin: top right;
  transform-origin: top right; }

.bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-tooltip {
  -webkit-transform-origin: center left;
  transform-origin: center left; }

.bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-tooltip {
  -webkit-transform-origin: center center;
  transform-origin: center center; }

.bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-tooltip {
  -webkit-transform-origin: center right;
  transform-origin: center right; }

.bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-tooltip {
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left; }

.bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-tooltip {
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center; }

.bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-tooltip {
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right; }

.bp3-tooltip .bp3-popover-content {
  background: #394b59;
  color: #f5f8fa; }

.bp3-tooltip .bp3-popover-arrow::before {
  -webkit-box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
  box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }

.bp3-tooltip .bp3-popover-arrow-border {
  fill: #10161a;
  fill-opacity: 0.1; }

.bp3-tooltip .bp3-popover-arrow-fill {
  fill: #394b59; }

.bp3-popover-enter > .bp3-tooltip, .bp3-popover-appear > .bp3-tooltip {
  -webkit-transform: scale(0.8);
  transform: scale(0.8); }

.bp3-popover-enter-active > .bp3-tooltip, .bp3-popover-appear-active > .bp3-tooltip {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-popover-exit > .bp3-tooltip {
  -webkit-transform: scale(1);
  transform: scale(1); }

.bp3-popover-exit-active > .bp3-tooltip {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  -webkit-transition-delay: 0;
  transition-delay: 0; }

.bp3-tooltip .bp3-popover-content {
  padding: 10px 12px; }

.bp3-tooltip.bp3-dark,
.bp3-dark .bp3-tooltip {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-tooltip.bp3-dark .bp3-popover-content,
.bp3-dark .bp3-tooltip .bp3-popover-content {
  background: #e1e8ed;
  color: #394b59; }

.bp3-tooltip.bp3-dark .bp3-popover-arrow::before,
.bp3-dark .bp3-tooltip .bp3-popover-arrow::before {
  -webkit-box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4);
  box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }

.bp3-tooltip.bp3-dark .bp3-popover-arrow-border,
.bp3-dark .bp3-tooltip .bp3-popover-arrow-border {
  fill: #10161a;
  fill-opacity: 0.2; }

.bp3-tooltip.bp3-dark .bp3-popover-arrow-fill,
.bp3-dark .bp3-tooltip .bp3-popover-arrow-fill {
  fill: #e1e8ed; }

.bp3-tooltip.bp3-intent-primary .bp3-popover-content {
  background: #137cbd;
  color: #ffffff; }

.bp3-tooltip.bp3-intent-primary .bp3-popover-arrow-fill {
  fill: #137cbd; }

.bp3-tooltip.bp3-intent-success .bp3-popover-content {
  background: #0f9960;
  color: #ffffff; }

.bp3-tooltip.bp3-intent-success .bp3-popover-arrow-fill {
  fill: #0f9960; }

.bp3-tooltip.bp3-intent-warning .bp3-popover-content {
  background: #d9822b;
  color: #ffffff; }

.bp3-tooltip.bp3-intent-warning .bp3-popover-arrow-fill {
  fill: #d9822b; }

.bp3-tooltip.bp3-intent-danger .bp3-popover-content {
  background: #db3737;
  color: #ffffff; }

.bp3-tooltip.bp3-intent-danger .bp3-popover-arrow-fill {
  fill: #db3737; }

.bp3-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help; }

.bp3-tree .bp3-icon, .bp3-tree .bp3-icon-standard, .bp3-tree .bp3-icon-large {
  color: #5c7080; }

.bp3-tree .bp3-icon.bp3-intent-primary, .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-tree .bp3-icon-large.bp3-intent-primary {
  color: #137cbd; }

.bp3-tree .bp3-icon.bp3-intent-success, .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-tree .bp3-icon-large.bp3-intent-success {
  color: #0f9960; }

.bp3-tree .bp3-icon.bp3-intent-warning, .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-tree .bp3-icon-large.bp3-intent-warning {
  color: #d9822b; }

.bp3-tree .bp3-icon.bp3-intent-danger, .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-tree .bp3-icon-large.bp3-intent-danger {
  color: #db3737; }

.bp3-tree-node-list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.bp3-tree-root {
  position: relative;
  background-color: transparent;
  cursor: default;
  padding-left: 0; }

.bp3-tree-node-content-0 {
  padding-left: 0px; }

.bp3-tree-node-content-1 {
  padding-left: 23px; }

.bp3-tree-node-content-2 {
  padding-left: 46px; }

.bp3-tree-node-content-3 {
  padding-left: 69px; }

.bp3-tree-node-content-4 {
  padding-left: 92px; }

.bp3-tree-node-content-5 {
  padding-left: 115px; }

.bp3-tree-node-content-6 {
  padding-left: 138px; }

.bp3-tree-node-content-7 {
  padding-left: 161px; }

.bp3-tree-node-content-8 {
  padding-left: 184px; }

.bp3-tree-node-content-9 {
  padding-left: 207px; }

.bp3-tree-node-content-10 {
  padding-left: 230px; }

.bp3-tree-node-content-11 {
  padding-left: 253px; }

.bp3-tree-node-content-12 {
  padding-left: 276px; }

.bp3-tree-node-content-13 {
  padding-left: 299px; }

.bp3-tree-node-content-14 {
  padding-left: 322px; }

.bp3-tree-node-content-15 {
  padding-left: 345px; }

.bp3-tree-node-content-16 {
  padding-left: 368px; }

.bp3-tree-node-content-17 {
  padding-left: 391px; }

.bp3-tree-node-content-18 {
  padding-left: 414px; }

.bp3-tree-node-content-19 {
  padding-left: 437px; }

.bp3-tree-node-content-20 {
  padding-left: 460px; }

.bp3-tree-node-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-right: 5px; }

.bp3-tree-node-content:hover {
  background-color: rgba(191, 204, 214, 0.4); }

.bp3-tree-node-caret,
.bp3-tree-node-caret-none {
  min-width: 30px; }

.bp3-tree-node-caret {
  color: #5c7080;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  padding: 7px;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-tree-node-caret:hover {
  color: #182026; }

.bp3-dark .bp3-tree-node-caret {
  color: #a7b6c2; }

.bp3-dark .bp3-tree-node-caret:hover {
  color: #f5f8fa; }

.bp3-tree-node-caret.bp3-tree-node-caret-open {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.bp3-tree-node-caret.bp3-icon-standard::before {
  content: ""; }

.bp3-tree-node-icon {
  position: relative;
  margin-right: 7px; }

.bp3-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-tree-node-label span {
  display: inline; }

.bp3-tree-node-secondary-label {
  padding: 0 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-tree-node-secondary-label .bp3-popover-wrapper,
.bp3-tree-node-secondary-label .bp3-popover-target {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.bp3-tree-node.bp3-disabled .bp3-tree-node-content {
  background-color: inherit;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-tree-node.bp3-disabled .bp3-tree-node-caret,
.bp3-tree-node.bp3-disabled .bp3-tree-node-icon {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.6); }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #137cbd; }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-standard, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-large {
  color: #ffffff; }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret::before {
  color: rgba(255, 255, 255, 0.7); }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret:hover::before {
  color: #ffffff; }

.bp3-dark .bp3-tree-node-content:hover {
  background-color: rgba(92, 112, 128, 0.3); }

.bp3-dark .bp3-tree .bp3-icon, .bp3-dark .bp3-tree .bp3-icon-standard, .bp3-dark .bp3-tree .bp3-icon-large {
  color: #a7b6c2; }

.bp3-dark .bp3-tree .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-primary {
  color: #137cbd; }

.bp3-dark .bp3-tree .bp3-icon.bp3-intent-success, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-success {
  color: #0f9960; }

.bp3-dark .bp3-tree .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-warning {
  color: #d9822b; }

.bp3-dark .bp3-tree .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-danger {
  color: #db3737; }

.bp3-dark .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #137cbd; }

/*# sourceMappingURL=blueprint.css.map */
